import { BackendApi } from "./HttpBaseService";

const ObtenerConcursoActual = async () => {
  return await BackendApi.get(`Concurso/Actual`);
};

const ObtenerConcursos = async () => {
  return await BackendApi.get(`Concurso/Todos`);
};

const CrearConcurso = async concurso => {
  return await BackendApi.post(`Concurso/Crear`, concurso);
};

const EliminarConcurso = async id => {
  return await BackendApi.delete(`Concurso/Eliminar?id=${id}`);
};

const ModificarConcurso = async (id, concurso) => {
  return await BackendApi.put(`Concurso/${id}/Modificar`, concurso);
};

const ObtenerEtapas = async id => {
  return await BackendApi.get(`Concurso/${id}/Etapas`);
};

const CargarEtapaConcurso = async etapa => {
  return await BackendApi.post(`Concurso/CargarEtapa`, etapa);
};

const ObtenerEtapaPorId = async id => {
  return await BackendApi.get(`Concurso/EtapaPorId/${id}`);
};

const CambiarEstadoConcurso = async (id, estado) => {
  return await BackendApi.put(
    `Concurso/${id}/CambiarEstadoConcurso?estado=${estado}`
  );
};

const ObtenerConcursoPorId = async id => {
  return await BackendApi.get(`Concurso/${id}`);
};

const GuardarClasificadosLiguilla = async (id, clasificadosLiguilla) => {
  return await BackendApi.put(
    `Concurso/${id}/ClasificadosLiguilla`,
    clasificadosLiguilla
  );
};

const ObtenerResultadosConcurso = async id => {
  return await BackendApi.get(`Concurso/${id}/Resultados`);
};

const GuardarResultadosConcurso = async (id, resultados) => {
  return await BackendApi.put(`Concurso/${id}/GuardarResultados`, resultados);
};

const ModificarConcursoEstado = async (id, estado) => {
  return await BackendApi.put(
    `Concurso/${id}/ModificarEstado?concursoEstadoId=${estado}`
  );
};

const ObtenerClasificadosLiguilla = async id => {
  return await BackendApi.get(`Conjunto/ClasificadosLiguilla`);
};

const ConcursoService = {
  ObtenerConcursoActual,
  ObtenerConcursos,
  CrearConcurso,
  EliminarConcurso,
  ModificarConcurso,
  ObtenerEtapas,
  CargarEtapaConcurso,
  ObtenerEtapaPorId,
  CambiarEstadoConcurso,
  ObtenerConcursoPorId,
  GuardarClasificadosLiguilla,
  ObtenerResultadosConcurso,
  GuardarResultadosConcurso,
  ModificarConcursoEstado,
  ObtenerClasificadosLiguilla
};

export default ConcursoService;
