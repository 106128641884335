import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill
} from "react-icons/bs";
import DatePicker from "react-multi-date-picker";
import {
  formatDate,
  formatearHoraHHMM,
  restarUnDia,
  sumarUnDia
} from "../utils/dates";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import { Button } from "reactstrap";

const Home = () => {
  const [fecha, setFecha] = useState(new Date());
  const [programacionTablados, setProgramacionTablados] = useState([]);
  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(new Date());
  const [retornoCarga, setRetornoCarga] = useState();
  const fetchProgramacion = () => {
    sendRequest(
      Servicios.ObtenerProgramacionTablados,
      [new Date(fecha)],
      data => {
        setProgramacionTablados(data?.data);
      }
    );
  };
  const sendRequest = useRequestHandler();
  useEffect(() => {
    fetchProgramacion();
  }, [fecha]);

  return (
    <Fragment>
      <>
        <div className="d-flex row programacion-tablado-card">
          <div className="col-auto">Desde:</div>
          <div className="col-auto">
            <DatePicker
              format="DD/MM/YYYY"
              value={desde}
              onChange={setDesde}
              slotProps={{
                textField: {
                  size: "small",
                  inputProps: { value: formatDate(new Date(desde)) }
                }
              }}
            />
          </div>
          <div className="col-auto">Hasta:</div>
          <div className="col-auto">
            <DatePicker
              format="DD/MM/YYYY"
              value={hasta}
              onChange={setHasta}
              slotProps={{
                textField: {
                  size: "small",
                  inputProps: { value: formatDate(new Date(hasta)) }
                }
              }}
            />
          </div>
          <div className="col">
            <Button
              className="btn btn-primary"
              onClick={() => {
                sendRequest(
                  Servicios.CargaAutomaticaTabladoProgramacion,
                  [new Date(desde), new Date(hasta)],
                  data => {
                    setRetornoCarga(data?.data);
                    fetchProgramacion();
                  }
                );
              }}
            >
              CARGA AUTOMÁTICA
            </Button>
          </div>

          {process.env.REACT_APP_ENVIRONMENT == "LOCAL" && (
            <div className="col">
              <Button
                className="btn btn-danger"
                onClick={() => {
                  if (window.confirm("OJO CON ESTO!!!!")) {
                    sendRequest(
                      Servicios.GenerarProgramacionRandom,
                      [new Date(desde), new Date(hasta)],
                      () => {
                        fetchProgramacion();
                      }
                    );
                  }
                }}
              >
                CARGA ALEATORIA
              </Button>
            </div>
          )}
          {retornoCarga && (
            <div className="informacion-carga text-left">
              <h2 className="titulo-carga">Información de Carga</h2>
              <br></br>
              <ul className="lista-carga">
                <li>
                  <span className="negrita">
                    Cantidad de Horarios Insertados:
                  </span>{" "}
                  {retornoCarga.cantidadHorariosInsertados}
                </li>
                <li>
                  <span className="negrita">
                    Cantidad de Horarios Actualizados:
                  </span>{" "}
                  {retornoCarga.cantidadHorariosActualizados}
                </li>
                <li>
                  <span className="negrita">
                    Cantidad de Contratos Insertados:
                  </span>{" "}
                  {retornoCarga.cantidadContratosInsertados}
                </li>
                <li>
                  <span className="negrita">
                    Cantidad de Tablados Faltantes:
                  </span>{" "}
                  {retornoCarga.cantidadTabladosFaltantes}
                </li>
                <li>
                  <span className="negrita">
                    Cantidad de Conjuntos Faltantes:
                  </span>{" "}
                  {retornoCarga.cantidadConjuntosFaltantes}
                </li>
                <li>
                  <span className="negrita">
                    Cantidad de Excepciones Capturadas:
                  </span>{" "}
                  {retornoCarga.cantidadExcepcionesCapturadas}
                </li>
                <br></br>
                <li>
                  <span className="negrita">Novedades:</span>
                  <ul className="novedades-lista">
                    {retornoCarga.novedades.map((novedad, index) => (
                      <li key={index}>{novedad}</li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="d-flex row my-3 justify-content-between">
          <div className="col">
            <h4>Programación de tablados </h4>
          </div>
          <div className="col">
            <div className="d-flex row  ">
              <div className=" w-10">
                <BsFillArrowLeftCircleFill
                  className="m-2"
                  size={26}
                  onClick={() => setFecha(restarUnDia(fecha))}
                />
              </div>
              <div className="w-auto">
                <DatePicker
                  format="DD/MM/YYYY"
                  value={fecha}
                  onChange={setFecha}
                  slotProps={{
                    textField: {
                      size: "small",
                      inputProps: { value: formatDate(new Date(fecha)) }
                    }
                  }}
                />
              </div>
              <div className="w-10">
                <BsFillArrowRightCircleFill
                  className="m-2"
                  size={26}
                  onClick={() => setFecha(sumarUnDia(fecha))}
                />
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="d-flex row">
          {programacionTablados?.map(x => (
            <div className="programacion-tablado-card">
              <h6>{x.nombre}</h6>
              <hr></hr>
              {x?.conjuntos?.map(y => (
                <div className="item">
                  <div className="d-flex row mx-1">
                    <div className="col-auto">{y.orden}</div>
                    <div className="col text-left">{y?.nombre}</div>
                    <div className="col-auto">
                      {formatearHoraHHMM(y.fechaHora)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </>
    </Fragment>
  );
};

export default Home;
