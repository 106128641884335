import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import DatePicker from "react-multi-date-picker";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";

import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill
} from "react-icons/bs";
import { DataGrid } from "@mui/x-data-grid";
import { AddCircleOutline } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { formatDate, restarUnDia, sumarUnDia } from "../utils/dates";
import { showSuccessMessage } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useTypeThenRun } from "../utils/useTypeThenRun";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";

export const Programacion = () => {
  const { _tabladoId } = useParams();
  const [fecha, setFecha] = useState(new Date());
  const [textoBusquedaTmp, setTextoBusquedaTmp] = useState("");
  const [textoBusqueda, setTextoBusqueda] = useState("");
  const [conjuntos, setConjuntos] = useState([]);
  const [conjuntosActuan, setConjuntosActuan] = useState([]);
  const [tablados, setTablados] = useState([]);
  const [tabladoId, setTabladoId] = useState(_tabladoId);
  const [tablado, setTablado] = useState();
  const dispatch = useDispatch();
  const sendRequest = useRequestHandler();
  const [infoTitulo, setInfoTitulo] = useState("");
  const [infoDescripcion, setInfoDescripcion] = useState("");
  const [infoLinkEntradas, setInfoLinkEntradas] = useState("");

  const columnsConjuntos = [
    { field: "nombre", headerName: "Nombre", flex: 99 },
    {
      field: "actionAdd",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          setConjuntos(
            conjuntos.map(x => {
              if (x.id == params?.row?.id) {
                setConjuntosActuan([...conjuntosActuan, x]);
                return { ...x, agregado: true };
              } else {
                return x;
              }
            })
          );
          setTextoBusqueda("");
          setTextoBusquedaTmp("");
        };

        return <AddCircleOutline className="action-icon" onClick={onClick} />;
      }
    }
  ];

  const columnsConjuntosActuan = [
    { field: "nombre", headerName: "Nombre", flex: 99 },
    {
      field: "actionSubir",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          let indice = conjuntosActuan.indexOf(params.row);
          let arr = [...conjuntosActuan];
          arr.splice(indice, 1);
          arr.splice(indice - 1 >= 0 ? indice - 1 : 0, 0, params.row);
          setConjuntosActuan(arr);
        };

        return (
          <BiUpArrowAlt size={30} className="action-icon" onClick={onClick} />
        );
      }
    },
    {
      field: "actionBajar",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          let indice = conjuntosActuan.indexOf(params.row);
          let arr = [...conjuntosActuan];
          arr.splice(indice, 1);
          arr.splice(
            indice + 1 < arr.length ? indice + 1 : arr.length,
            0,
            params.row
          );
          setConjuntosActuan(arr);
        };

        return (
          <BiDownArrowAlt size={30} className="action-icon" onClick={onClick} />
        );
      }
    },
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          setConjuntosActuan(
            conjuntosActuan.filter(x => x.id != params?.row?.id)
          );
        };

        return <DeleteIcon className="action-icon" onClick={onClick} />;
      }
    }
  ];

  useEffect(() => {
    sendRequest(Servicios.ObtenerTablados, [], data => {
      setTablados(data?.data);
    });
  }, []);

  useTypeThenRun(textoBusquedaTmp, () => setTextoBusqueda(textoBusquedaTmp));

  useEffect(() => {
    sendRequest(Servicios.ConjuntosConcursan, [textoBusqueda], data => {
      setConjuntos(
        data?.data.filter(z => !conjuntosActuan.map(x => x.id).includes(z.id))
      );
    });
  }, [textoBusqueda, conjuntosActuan]);

  useEffect(() => {
    if (tabladoId > 0) {
      sendRequest(
        Servicios.ObtenerProgramacionTablado,
        [tabladoId, new Date(fecha)],
        data => {
          setTablado(data?.data);
          setInfoTitulo(data?.data?.infoTitulo ?? "");
          setInfoDescripcion(data?.data?.infoDescripcion ?? "");
          setInfoLinkEntradas(data?.data?.infoLinkEntradas ?? "");
          setConjuntosActuan(data?.data?.conjuntos);
        }
      );
    }
  }, [tabladoId, fecha]);

  const handleGuardar = () => {
    sendRequest(
      Servicios.CargarProgramacionTablado,
      [
        {
          tabladoId,
          fecha: new Date(fecha),
          conjuntosId: conjuntosActuan.map(x => x.id),
          infoTitulo,
          infoDescripcion,
          infoLinkEntradas
        }
      ],
      () => {
        dispatch(showSuccessMessage("Programación cargada correctamente"));
      }
    );
  };

  return (
    <>
      <>
        <div className="d-flex row my-3 justify-content-between">
          <div className="col-auto">
            {tablado ? (
              <h4>Programación de tablado </h4>
            ) : (
              <h4>Seleccione un tablado</h4>
            )}
          </div>
          <div className="col text-center">
            <Select
              style={{ width: 200 }}
              size="small"
              placeholder="Tablado"
              value={tabladoId}
              onChange={e => setTabladoId(e.target.value)}
            >
              {tablados?.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  {c.nombre}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            id="datepicker-programacion"
            className="col datepicker text-right"
          >
            <div className="d-flex row">
              <div className="w-10">
                <BsFillArrowLeftCircleFill
                  className="m-2"
                  size={26}
                  onClick={() => setFecha(restarUnDia(fecha))}
                />
              </div>
              <div className="w-auto">
                <DatePicker
                  format="DD/MM/YYYY"
                  value={fecha}
                  onChange={setFecha}
                  slotProps={{
                    textField: {
                      size: "small",
                      inputProps: { value: formatDate(new Date(fecha)) }
                    }
                  }}
                />
              </div>
              <div className="w-10">
                <BsFillArrowRightCircleFill
                  className="m-2"
                  size={26}
                  onClick={() => setFecha(sumarUnDia(fecha))}
                />
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div>
          <TextField
            className="w-100 my-1"
            size="small"
            label="Título"
            value={infoTitulo}
            onChange={e => setInfoTitulo(e.target.value)}
          />
          <TextField
            className="w-100 my-1"
            size="small"
            label="Descripcion"
            multiline
            rows={3}
            value={infoDescripcion}
            onChange={e => setInfoDescripcion(e.target.value)}
          />
          <TextField
            className="w-100 my-1"
            size="small"
            label="Link entradas"
            value={infoLinkEntradas}
            onChange={e => setInfoLinkEntradas(e.target.value)}
          />
        </div>
        <hr></hr>
        {tablado && (
          <>
            <div className="d-flex row">
              <div className="col-3 m-2">
                <TextField
                  className="w-100 my-1"
                  size="small"
                  label="Buscar conjunto"
                  value={textoBusquedaTmp}
                  onChange={e => setTextoBusquedaTmp(e.target.value)}
                />

                <DataGrid
                  style={{ maxHeight: 400, overflow: "auto" }}
                  pagination={false}
                  disableColumnFilter
                  disableColumnMenu
                  disableDensitySelector
                  hideFooterPagination
                  rows={conjuntos}
                  columns={columnsConjuntos}
                  checkboxSelection={false}
                  className="no-header"
                />
              </div>
              <div className="col-8 my-3">
                <DataGrid
                  style={{ maxHeight: 400, overflow: "auto" }}
                  pagination={false}
                  disableColumnFilter
                  disableColumnMenu
                  disableDensitySelector
                  hideFooterPagination
                  rows={conjuntosActuan}
                  columns={columnsConjuntosActuan}
                  checkboxSelection={false}
                />
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    window.history.go(-1);
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default withAuthenticationRequired(Programacion, {
  onRedirecting: () => <Loading />
});
