import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "../utils/useSnackbar";

const SnackbarWrapper = () => {
  const {
    openSuccessSnackbar,
    openErrorSnackbar,
    openInfoSnackbar,
    SnackbarComponent
  } = useSnackbar();

  const snackbarMsg = useSelector(state => state.snackbarMsg);

  useEffect(() => {
    if (snackbarMsg && snackbarMsg?.mensaje) {
      if (snackbarMsg?.tipo == "error") openErrorSnackbar(snackbarMsg?.mensaje);
      if (snackbarMsg?.tipo == "success")
        openSuccessSnackbar(snackbarMsg?.mensaje);
      if (snackbarMsg?.tipo == "info") openInfoSnackbar(snackbarMsg?.mensaje);
    }
  }, [snackbarMsg]);

  return <>{SnackbarComponent}</>;
};

export default SnackbarWrapper;
