import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { TextField, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import {
  formatDate,
  formatearFechaDDMMYY,
  formatearFechayyyyMMdd
} from "../utils/dates";
import DatePicker from "react-multi-date-picker";
import PDFReport from "../components/PDFreport";
import { FaFilePdf } from "react-icons/fa";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill
} from "react-icons/bs";

export const Anunciantes = () => {
  const [anunciantes, setAnunciantes] = useState([]);
  const [agregando, setAgregando] = useState(false);
  const [nombre, setNombre] = useState("");
  const [contacto, setContacto] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const [facturas, setFacturas] = useState([]);
  const sendRequest = useRequestHandler();
  const [fecha, setFecha] = useState(new Date());

  useEffect(() => {
    refrescarGrilla();
  }, []);

  const refrescarGrilla = () => {
    sendRequest(Servicios.ObtenerAnunciantes, [], data => {
      setAnunciantes(data?.data);
    });
  };

  const obtenerFacturas = id => {
    sendRequest(Servicios.ObtenerFacturas, [id], data => {
      setFacturas(
        data?.data.map(x => {
          return {
            ...x,
            fechaDesde: formatearFechaDDMMYY(x.fechaDesde),
            fechaHasta: formatearFechaDDMMYY(x.fechaHasta)
          };
        })
      );
    });
  };

  const limpiarForm = () => {
    setNombre("");
    setContacto("");
    setObservaciones("");
  };

  const eliminarAnunciante = id => {
    sendRequest(Servicios.eliminarAnunciante, [id], refrescarGrilla);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 10 },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 30,
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setIdEditando(params.row.id);
          setNombre(params.row.nombre);
          setContacto(params.row.contacto);
          setObservaciones(params.row.observaciones);
          obtenerFacturas(params.row.id);
        };

        return (
          <a href="#" onClick={onClick}>
            {params.row.nombre}
          </a>
        );
      }
    },
    { field: "contacto", headerName: "Contacto", flex: 30 },
    { field: "observaciones", headerName: "Observaciones", flex: 30 },
    {
      field: "actionEdit",
      headerName: "",
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setIdEditando(params.row.id);
          setNombre(params.row.nombre);
          setContacto(params.row.contacto);
          setObservaciones(params.row.observaciones);
          obtenerFacturas(params.row.id);
        };

        return <EditIcon className="action-icon" onClick={onClick} />;
      }
    },
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar el Anunciante?")) {
            eliminarAnunciante(params?.row?.id);
          }
          refrescarGrilla();
        };

        return <DeleteIcon className="action-icon" onClick={onClick} />;
      }
    }
  ];

  const customButton = row => {
    return <PDFReport data={[row]}></PDFReport>;
  };

  const columnsFacturas = [
    { field: "id", headerName: "ID", flex: 10 },
    { field: "fechaDesde", headerName: "Desde", flex: 30 },
    { field: "fechaHasta", headerName: "Hasta", flex: 30 },
    { field: "nivelContratacionNombre", headerName: "Nivel", flex: 30 },
    { field: "importe", headerName: "Importe", flex: 30 },
    {
      field: "actionPDF",
      headerName: "",
      sortable: false,
      renderCell: params => {
        return customButton(params.row);
      }
    }
  ];

  const nuevaFactura = fecha => {
    sendRequest(
      Servicios.FacturarAnunciante,
      [idEditando, formatearFechayyyyMMdd(new Date(fecha))],
      () => {
        obtenerFacturas(idEditando);
      }
    );
  };

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        Servicios.ModificarAnunciante,
        [
          {
            id: idEditando,
            nombre: nombre,
            contacto: contacto,
            observaciones: observaciones
          }
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    } else {
      sendRequest(
        Servicios.CrearAnunciante,
        [
          {
            nombre,
            contacto,
            observaciones
          }
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    }
  };

  return (
    <>
      <>
        {!agregando ? (
          <>
            <div className="d-flex row m-2 justify-content-between">
              <div className="col">
                <h4> Anunciantes</h4>
              </div>

              <div>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    setAgregando(true);

                    setIdEditando(0);
                    setNombre("");
                    setContacto("");
                    setObservaciones("");
                  }}
                >
                  Agregar
                </Button>
              </div>
            </div>
            <hr></hr>
            <div
              className="row m-2 justify-content-center"
              style={{ width: "100%" }}
            >
              {anunciantes?.length > 0 ? (
                <DataGrid
                  rows={anunciantes}
                  columns={columns}
                  checkboxSelection={false}
                />
              ) : (
                <h5 className="">No se encontraron resultados</h5>
              )}
            </div>
          </>
        ) : (
          <div className="justify-content-center w-auto d-flex row">
            <div className="col">
              <div className="d-flex row justify-content-between">
                <div className="col m-2">
                  <h4>
                    {editando ? "Modificar Anunciante" : "Nuevo Anunciante"}
                  </h4>
                </div>
              </div>
              <div className="col m-2">
                <TextField
                  className="w-100"
                  size="small"
                  label="Nombre"
                  value={nombre}
                  onChange={e => setNombre(e.target.value)}
                />
              </div>
              <div className="col m-2">
                <TextField
                  className="w-100"
                  size="small"
                  label="Contacto"
                  value={contacto}
                  onChange={e => setContacto(e.target.value)}
                />
              </div>
              <div className="col m-2">
                <TextField
                  className="w-100"
                  size="small"
                  label="Observaciones"
                  value={observaciones}
                  onChange={e => setObservaciones(e.target.value)}
                />
              </div>
              <br></br>
              <div className="d-flex row">
                <h5>Facturas</h5>
                <div className="col"></div>
                <div
                  id="datepicker-programacion"
                  className="col datepicker text-right"
                >
                  <div className="d-flex row">
                    <div className="w-auto">
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={fecha}
                        onChange={setFecha}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <Button
                    variant={"contained"}
                    onClick={() => nuevaFactura(fecha)}
                  >
                    Nueva factura
                  </Button>
                </div>
              </div>
              <div
                className="row m-2 justify-content-center"
                style={{ width: "100%" }}
              >
                {facturas?.length > 0 ? (
                  <DataGrid
                    rows={facturas}
                    columns={columnsFacturas}
                    checkboxSelection={false}
                  />
                ) : (
                  <h5 className="">No se encontraron facturas</h5>
                )}
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    limpiarForm();
                    setEditando(false);
                    setAgregando(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default withAuthenticationRequired(Anunciantes, {
  onRedirecting: () => <Loading />
});
