import { BackendApi } from "./HttpBaseService";

const ObtenerAnunciantes = async () => {
  return await BackendApi.get(`Anunciante/Anunciantes`);
};

const ObtenerBanners = async incluirInactivos => {
  return await BackendApi.get(
    `Banner?cantidad=0&incluirInactivos=${incluirInactivos}`
  );
};

const CrearBanner = async (banner, imagen, imagenModal) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  formData.append("imagenModal", imagenModal);
  const json = JSON.stringify(banner);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("banner", blob);
  return BackendApi.post(`Banner/Crear`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const ModificarBanner = async (banner, imagen, imagenModal) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  formData.append("imagenModal", imagenModal);
  const json = JSON.stringify(banner);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("banner", blob);
  return BackendApi.put(`Banner/Modificar`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const CrearAnunciante = async anunciante => {
  return await BackendApi.post(`Anunciante/CrearAnunciante`, anunciante);
};

const ModificarAnunciante = async anunciante => {
  return await BackendApi.put(`Anunciante/ModificarAnunciante`, anunciante);
};

const EliminarBanner = async bannerId => {
  return await BackendApi.delete(`Banner/${bannerId}`);
};

const ObtenerFacturas = async anuncianteId => {
  return await BackendApi.get(`Anunciante/${anuncianteId}/Facturas`);
};

const FacturarAnunciante = async (anuncianteId, fecha) => {
  return await BackendApi.post(
    `Anunciante/FacturarAnunciante?anuncianteId=${anuncianteId}&fechaFin=${fecha}`
  );
};

const ObtenerDatosDashboard = async () => {
  return await BackendApi.get(`AppUtils/datos-dashboard`);
};

const BannerService = {
  ObtenerAnunciantes,
  CrearBanner,
  ObtenerBanners,
  ModificarBanner,
  EliminarBanner,
  CrearAnunciante,
  ModificarAnunciante,
  ObtenerFacturas,
  FacturarAnunciante,
  ObtenerDatosDashboard
};

export default BannerService;
