import React, { useEffect, useRef, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestHandler } from "../utils/useRequestHandler";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";

import PronosticarCategoria from "./PronosticarCategoria";
import { showSuccessMessage } from "../redux/actions";
import Servicios from "../services";
import {
  COMPARSAS,
  HUMORISTAS,
  MURGAS,
  PARODISTAS,
  REVISTAS
} from "../utils/constants";

export const Resultados = () => {
  const { _concursoId } = useParams();
  const [concurso, setConcurso] = useState({});
  const [murgas, setMurgas] = useState([]);
  const [parodistas, setParodistas] = useState([]);
  const [humoristas, setHumoristas] = useState([]);
  const [revistas, setRevistas] = useState([]);
  const [comparsas, setComparsas] = useState([]);
  const sendRequest = useRequestHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGuardar = () => {
    sendRequest(
      Servicios.GuardarResultadosConcurso,
      [_concursoId, { murgas, parodistas, humoristas, revistas, comparsas }],
      () => dispatch(showSuccessMessage("Concurso actualizado correctamente"))
    );
  };

  useEffect(() => {
    sendRequest(Servicios.ObtenerResultadosConcurso, [_concursoId], data => {
      sendRequest(
        Servicios.ObtenerResultadosConcurso,
        [_concursoId],
        dataConcurso => {
          setConcurso(dataConcurso?.data);
          setMurgas(
            data?.data?.murgas?.length > 0
              ? data?.data?.murgas
              : dataConcurso?.data?.conjuntos?.filter(
                  x => x.categoria == MURGAS && x.clasificadoLiguilla
                )
          );
          setParodistas(
            data?.data?.parodistas?.length > 0
              ? data?.data?.parodistas
              : dataConcurso?.data?.conjuntos?.filter(
                  x => x.categoria == PARODISTAS && x.clasificadoLiguilla
                )
          );
          setHumoristas(
            data?.data?.humoristas?.length > 0
              ? data?.data?.humoristas
              : dataConcurso?.data?.conjuntos?.filter(
                  x => x.categoria == HUMORISTAS && x.clasificadoLiguilla
                )
          );
          setRevistas(
            data?.data?.revistas?.length > 0
              ? data?.data?.revistas
              : dataConcurso?.data?.conjuntos?.filter(
                  x => x.categoria == REVISTAS && x.clasificadoLiguilla
                )
          );
          setComparsas(
            data?.data?.comparsas?.length > 0
              ? data?.data?.comparsas
              : dataConcurso?.data?.conjuntos?.filter(
                  x => x.categoria == COMPARSAS && x.clasificadoLiguilla
                )
          );
        }
      );
    });
  }, [_concursoId]);

  return (
    <>
      <div className="justify-content-around ">
        <div className="d-flex row m-2 justify-content-between">
          <div className="col">
            <h4>{`Cargar resultados de concurso`}</h4>
          </div>
        </div>

        <hr></hr>
        <div className={"d-flex row justify-content-around pronostico"}>
          <div className="col ">
            <PronosticarCategoria
              title="Murgas"
              conjuntos={murgas}
              setConjuntos={setMurgas}
            />

            <PronosticarCategoria
              title="Parodistas"
              conjuntos={parodistas}
              setConjuntos={setParodistas}
            />
          </div>
          <div className="col">
            <PronosticarCategoria
              title="Humoristas"
              conjuntos={humoristas}
              setConjuntos={setHumoristas}
            />
            <PronosticarCategoria
              title="Revistas"
              conjuntos={revistas}
              setConjuntos={setRevistas}
            />
            <PronosticarCategoria
              title="Comparsas"
              conjuntos={comparsas}
              setConjuntos={setComparsas}
            />
          </div>
        </div>
        <hr></hr>
        <div className="d-flex row justify-content-center w-auto">
          <div className="col text-left">
            <Button
              variant={"outlined"}
              onClick={() => {
                navigate("/backoffice/Concursos");
              }}
            >
              Cancelar
            </Button>
          </div>
          <div className="col text-right">
            <Button variant={"contained"} onClick={handleGuardar}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(Resultados, {
  onRedirecting: () => <Loading />
});
