import React, { useEffect, useRef, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestHandler } from "../utils/useRequestHandler";
import { useDispatch } from "react-redux";
import { Button, Switch } from "@mui/material";

import PronosticarCategoria from "./PronosticarCategoria";
import { showSuccessMessage } from "../redux/actions";
import Servicios from "../services";

export const Pronosticar = () => {
  const { _pencaId, _usuarioId } = useParams();
  const [pencaNombre, setPencaNombre] = useState("");
  const [murgas, setMurgas] = useState([]);
  const [parodistas, setParodistas] = useState([]);
  const [humoristas, setHumoristas] = useState([]);
  const [revistas, setRevistas] = useState([]);
  const [comparsas, setComparsas] = useState([]);
  const [permitirPronosticar, setPermitirPronosticar] = useState(!_usuarioId);
  const [usuarioNombre, setUsuarioNombre] = useState("");
  const sendRequest = useRequestHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [incluirEliminados, setIncluirEliminados] = useState(false);

  const handleGuardar = () => {
    sendRequest(
      Servicios.GuardarPronostico,
      [_pencaId, { murgas, parodistas, humoristas, revistas, comparsas }],
      () => dispatch(showSuccessMessage("Pronóstico actualizado"))
    );
  };

  useEffect(() => {
    _usuarioId &&
      sendRequest(Servicios.ObtenerUsuarioPorId, [_usuarioId], data =>
        setUsuarioNombre(data?.data?.nombreCompleto)
      );
  }, [_usuarioId]);

  useEffect(() => {
    sendRequest(
      Servicios.ObtenerPronostico,
      [_pencaId, incluirEliminados, _usuarioId ?? 0],
      data => {
        setPencaNombre(data?.data?.pencaNombre);
        setMurgas(data?.data?.murgas);
        setParodistas(data?.data?.parodistas);
        setHumoristas(data?.data?.humoristas);
        setRevistas(data?.data?.revistas);
        setComparsas(data?.data?.comparsas);
        setPermitirPronosticar(!_usuarioId);
      }
    );
  }, [_pencaId, incluirEliminados]);

  return (
    <>
      <div className="justify-content-around ">
        <div className="d-flex row m-2 justify-content-between">
          <div className="col">
            <h4>{`${
              _usuarioId
                ? `Pronóstico de ${usuarioNombre} en`
                : "Pronosticar en"
            } penca ${pencaNombre}`}</h4>
          </div>

          <div className="col">
            Incluir eliminados
            <Switch
              checked={incluirEliminados}
              onChange={e => setIncluirEliminados(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </div>

        <hr></hr>
        <div
          className={`d-flex row justify-content-around ${
            permitirPronosticar ? "pronostico" : ""
          }`}
        >
          <div className="col ">
            <PronosticarCategoria
              title="Murgas"
              conjuntos={murgas}
              setConjuntos={setMurgas}
              disabled={!permitirPronosticar}
              incluirEliminados
            />

            <PronosticarCategoria
              title="Parodistas"
              conjuntos={parodistas}
              setConjuntos={setParodistas}
              disabled={!permitirPronosticar}
              incluirEliminados
            />
          </div>
          <div className="col">
            <PronosticarCategoria
              title="Humoristas"
              conjuntos={humoristas}
              setConjuntos={setHumoristas}
              disabled={!permitirPronosticar}
              incluirEliminados
            />
            <PronosticarCategoria
              title="Revistas"
              conjuntos={revistas}
              setConjuntos={setRevistas}
              disabled={!permitirPronosticar}
              incluirEliminados
            />
            <PronosticarCategoria
              title="Comparsas"
              conjuntos={comparsas}
              setConjuntos={setComparsas}
              disabled={!permitirPronosticar}
              incluirEliminados
            />
          </div>
        </div>
        <hr></hr>
        <div className="d-flex row justify-content-center w-auto">
          <div className="col text-left">
            <Button
              variant={"outlined"}
              onClick={() => {
                navigate("/backoffice/Pencas");
              }}
            >
              Cancelar
            </Button>
          </div>
          <div className="col text-right">
            <Button
              variant={"contained"}
              onClick={handleGuardar}
              disabled={!permitirPronosticar}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(Pronosticar, {
  onRedirecting: () => <Loading />
});
