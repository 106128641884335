import React from "react";
import jsPDF from "jspdf";
import { formatearFechaDDMMYY } from "../utils/dates";
import { FaFilePdf } from "react-icons/fa";

class PDFReport extends React.Component {
  generatePDF = () => {
    // Crea una instancia de jsPDF.
    const doc = new jsPDF();
    let anuncianteNombre, fechaDesde, fechaHasta;
    // Establece el margen superior inicial.
    let marginTop = 20;

    // Obtiene los datos del props.
    const data = this.props.data;

    // Recorre los datos y agrega la información al PDF.
    data.forEach((item, index) => {
      //   item.lineas = [...item.lineas, ...item.lineas, ...item.lineas];
      anuncianteNombre = item.anuncianteNombre;
      fechaDesde = item.fechaDesde;
      fechaHasta = item.fechaHasta;
      const pageHeight = doc.internal.pageSize.height;

      // Verifica si es necesario agregar una nueva página.
      if (marginTop + 10 > pageHeight) {
        doc.addPage(); // Agrega una nueva página
        marginTop = 20; // Resetea el margen superior
      }

      // Título de la factura (Anunciante y fechas)
      doc.setFontSize(16);

      doc.setFont("helvetica", "bold");
      doc.text(
        `CarnavalApp - Cargos generados por concepto publicitario`,
        10,
        marginTop
      );
      marginTop += 10;
      doc.setTextColor(0, 0, 255); // Color azul
      doc.text(`Anunciante: ${item.anuncianteNombre}`, 10, marginTop);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0); // Color negro
      doc.setFont("helvetica", "normal");

      doc.text(
        `Desde: ${item.fechaDesde}\tHasta: ${item.fechaHasta}\tNivel: ${item.nivelContratacionNombre}`,
        10,
        marginTop + 10
      );

      marginTop += 30;
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Fecha", 10, marginTop);
      doc.text("Usuarios activos", 60, marginTop);
      doc.text("Importe", 110, marginTop);

      doc.setFont("helvetica", "normal");
      // Si hay líneas de datos, agrégalas al PDF.
      if (item.lineas && item.lineas.length > 0) {
        marginTop += 10; // Aumenta el margen superior para las líneas

        item.lineas.forEach(linea => {
          // Verifica si es necesario agregar una nueva página.
          if (marginTop + 5 > pageHeight) {
            doc.addPage(); // Agrega una nueva página
            marginTop = 20; // Resetea el margen superior
          }

          doc.setLineWidth(0.2); // Grosor de la línea
          doc.line(10, marginTop + 1, 140, marginTop + 1);
          // Detalle de línea
          doc.text(` ${formatearFechaDDMMYY(linea.fecha)}`, 10, marginTop);
          doc.text(`${linea.cantidadUsuariosActivos}`, 60, marginTop);
          doc.text(` $${linea.importe.toFixed(2)}`, 110, marginTop);

          marginTop += 5; // Incrementa el margen superior
        });
      }

      // Importe total
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(
        `Importe total: $${item.importe.toFixed(2)}`,
        10,
        marginTop + 10
      );
      marginTop += 20; // Incrementa el margen superior
    });

    // Guarda el documento PDF.
    doc.save(`${anuncianteNombre}_${fechaDesde}_${fechaHasta}.pdf`);
  };

  render() {
    return (
      <FaFilePdf
        className="action-icon"
        onClick={() => {
          this.generatePDF();
        }}
      />
    );
  }
}

export default PDFReport;
