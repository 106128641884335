import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  LoadScript,
} from "@react-google-maps/api";
import Loading from "./Loading";
import LoadingGoogleMaps from "./LoadingGoogleMaps";

export const GoogleMapsSearch = ({
  title,
  texto,
  setTexto,
  marker,
  setMarker,
}) => {
  const center = { lat: -34.895033, lng: -56.165009 };
  const libraries = ["places"];
  const [selectedPlace, setSelectedPlace] = useState(null);
  const searchBoxRef = useRef(null);

  const handleMapClick = (event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };
  const onLoad = (searchBox) => {
    searchBoxRef.current = searchBox;

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }
      setSelectedPlace(places[0]);
    });
  };

  useEffect(() => {
    if (selectedPlace) {
      setMarker({
        lat: selectedPlace?.geometry?.location?.lat(),
        lng: selectedPlace?.geometry?.location?.lng(),
      });
      setTexto(selectedPlace?.name);
    }
  }, [selectedPlace]);

  return (
    <>
      <h6 className="mt-2 mb-1">{title}</h6>

      <LoadScript
        id="script-loader"
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={libraries}
        language={"es"}
        loadingElement={<LoadingGoogleMaps />}
      >
        <div className="m-2">
          <StandaloneSearchBox
            className="col "
            id="buscador-google-maps"
            onLoad={onLoad}
            options={{
              bounds: {
                north: center.lat + 0.2,
                south: center.lat - 0.2,
                east: center.lng + 0.2,
                west: center.lng - 0.2,
              },
              componentRestrictions: { country: "uy" },
            }}
          >
            <TextField
              className="w-100"
              size="small"
              value={texto}
              onChange={(e) => setTexto(e.target.value)}
            />
          </StandaloneSearchBox>
        </div>
        <GoogleMap
          mapContainerClassName="map-container"
          center={marker}
          zoom={15}
          onClick={handleMapClick}
          options={options}
        >
          {marker && <Marker position={marker} />}
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default GoogleMapsSearch;
