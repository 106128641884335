import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "../assets/logo.svg";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });

  return (
    <div className="nav-container">
      <Navbar className="col m-auto">
        <Container>
          <Nav className=" " navbar>
            {!isAuthenticated && (
              <NavItem>
                <Button
                  id="qsLoginBtn"
                  color="primary"
                  onClick={() => loginWithRedirect()}
                >
                  Log in
                </Button>
              </NavItem>
            )}
            {isAuthenticated && (
              <Button onClick={() => logoutWithRedirect()}>
                <FontAwesomeIcon className="mx-2" icon="power-off" />
                <span className="mx-2">Salir</span>
              </Button>
            )}

            <NavItem className="mt-4">
              <NavLink
                tag={RouterNavLink}
                to="/backoffice/"
                exact
                activeClassName="router-link-exact-active"
              >
                Programación de tablados
              </NavLink>
            </NavItem>

            {isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/backoffice/conjuntos"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Conjuntos
                </NavLink>
              </NavItem>
            )}
            {isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/backoffice/concursos"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Concursos
                </NavLink>
              </NavItem>
            )}
            {isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/backoffice/tablados"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Tablados
                </NavLink>
              </NavItem>
            )}
            {isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/backoffice/pencas"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Pencas
                </NavLink>
              </NavItem>
            )}
            {isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/backoffice/notificaciones"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Notificaciones
                </NavLink>
              </NavItem>
            )}
            {isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/backoffice/anunciantes"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Anunciantes
                </NavLink>
              </NavItem>
            )}
            {isAuthenticated && (
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/backoffice/banners"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Banners
                </NavLink>
              </NavItem>
            )}
          </Nav>

          {!isAuthenticated && (
            <Nav className="d-md-none" navbar>
              <NavItem>
                <Button
                  id="qsLoginBtn"
                  color="primary"
                  block
                  onClick={() => loginWithRedirect({})}
                >
                  Log in
                </Button>
              </NavItem>
            </Nav>
          )}
          {isAuthenticated && (
            <Nav
              className="d-md-none justify-content-between"
              navbar
              style={{ minHeight: 170 }}
            >
              <NavItem>
                <span className="user-info">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="nav-user-profile d-inline-block rounded-circle mr-3"
                    width="50"
                  />
                  <h6 className="d-inline-block">{user.name}</h6>
                </span>
              </NavItem>
              <NavItem>
                <FontAwesomeIcon icon="user" className="mr-3" />
                <RouterNavLink
                  to="/profile"
                  activeClassName="router-link-exact-active"
                >
                  Profile
                </RouterNavLink>
              </NavItem>
              <NavItem>
                <FontAwesomeIcon icon="power-off" className="mr-3" />
                <RouterNavLink
                  to="#"
                  id="qsLogoutBtn"
                  onClick={() => logoutWithRedirect()}
                >
                  Log out
                </RouterNavLink>
              </NavItem>
            </Nav>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
