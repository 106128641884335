import React, { useEffect } from "react";
import { Button } from "reactstrap";

const ApkDownload = () => {
  useEffect(() => {
    window.location.replace(
      "https://fotosappcarnaval.blob.core.windows.net/apkreleases/app-release.apk"
    );
  }, []);

  return <div></div>;
};

export default ApkDownload;
