import React, { useEffect, useRef, useState } from "react";
import { Box, MenuItem, Select, Slider } from "@mui/material";
import "./LandingStyles.scss";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import { useDispatch } from "react-redux";
import { formatearFechaDDMMYYHHMM } from "../utils/dates";
import { IoReload } from "react-icons/io5";
import Loading from "../components/Loading";
import { Button, Spinner } from "reactstrap";

const ResultadosLiguilla = () => {
  const sendRequest = useRequestHandler();
  const [datosDashboard, setDatosDashboard] = useState({});
  const [loading, setLoading] = useState(false);
  const obtenerDatos = () => {
    setLoading(true);
    sendRequest(Servicios.ObtenerClasificadosLiguilla, [], data => {
      setLoading(false);
      setDatosDashboard(data?.data);
      console.log(data.data);
    });
  };

  useEffect(() => {
    obtenerDatos();
  }, []);

  return (
    <div id="landing-website" className="container-center">
      <br></br>
      <div className="logo-carnavalapp" alt="Carnaval App"></div>
      <div
        className="logo-carnavalapp-eslogan"
        alt="La app del Carnaval Uruguayo"
      ></div>
      <div>
        {datosDashboard && (
          <div>
            <div className="calculadora text-center justify-text-around">
              <div className="hablemos-titulo d-flex row ">Liguilla 2024</div>
              {loading || !datosDashboard?.clasificados?.length ? (
                <div className="m-4">
                  <Spinner />
                </div>
              ) : (
                <div>
                  <h4 className="hablemos-texto d-flex row ">Murgas</h4>

                  {datosDashboard?.clasificados
                    .filter(x => x.categoria == "MURGAS")
                    .map(x => (
                      <div className="conjunto-clasificado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  {datosDashboard?.eliminados
                    .filter(x => x.categoria == "MURGAS")
                    .map(x => (
                      <div className="conjunto-eliminado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  <hr></hr>
                  <br></br>
                  <h4 className="hablemos-texto d-flex row ">Parodistas</h4>

                  {datosDashboard?.clasificados
                    .filter(x => x.categoria == "PARODISTAS")
                    .map(x => (
                      <div className="conjunto-clasificado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  {datosDashboard?.eliminados
                    .filter(x => x.categoria == "PARODISTAS")
                    .map(x => (
                      <div className="conjunto-eliminado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  <hr></hr>
                  <br></br>
                  <h4 className="hablemos-texto d-flex row ">Humoristas</h4>

                  {datosDashboard?.clasificados
                    .filter(x => x.categoria == "HUMORISTAS")
                    .map(x => (
                      <div className="conjunto-clasificado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  {datosDashboard?.eliminados
                    .filter(x => x.categoria == "HUMORISTAS")
                    .map(x => (
                      <div className="conjunto-eliminado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  <hr></hr>
                  <br></br>
                  <h4 className="hablemos-texto d-flex row ">Revistas</h4>

                  {datosDashboard?.clasificados
                    .filter(x => x.categoria == "REVISTAS")
                    .map(x => (
                      <div className="conjunto-clasificado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  {datosDashboard?.eliminados
                    .filter(x => x.categoria == "REVISTAS")
                    .map(x => (
                      <div className="conjunto-eliminado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  <hr></hr>
                  <br></br>
                  <h4 className="hablemos-texto d-flex row ">
                    Sociedades de negros y lubolos
                  </h4>

                  {datosDashboard?.clasificados
                    .filter(x => x.categoria == "COMPARSAS")
                    .map(x => (
                      <div className="conjunto-clasificado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                  {datosDashboard?.eliminados
                    .filter(x => x.categoria == "COMPARSAS")
                    .map(x => (
                      <div className="conjunto-eliminado">
                        <span>{x.nombre}</span>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <hr></hr>
            <div className="text-center">
              <div className="w-100 call-to-action">DESCARGÁ CARNAVAL APP</div>
              <div className="tiendas d-flex row ">
                <a
                  target={
                    !process.env.REACT_APP_GOOGLE_PLAY_URL ? "_self" : "_blank"
                  }
                  className="google-play col"
                  href={
                    process.env.REACT_APP_GOOGLE_PLAY_URL ??
                    "javascript:void(0);"
                  }
                ></a>
                <a
                  target={
                    !process.env.REACT_APP_GOOGLE_PLAY_URL ? "_self" : "_blank"
                  }
                  className="app-store col"
                  href={
                    process.env.REACT_APP_APP_STORE_URL ?? "javascript:void(0);"
                  }
                ></a>
              </div>
            </div>
          </div>
        )}{" "}
      </div>
    </div>
  );
};

export default ResultadosLiguilla;
