import React from "react";
import "./Calculadora.scss";

const EliminarCuenta = () => {
  return (
    <div id="calculadora-page" className="w-100 ">
      <div className="container py-4">
        <h1>Carnaval App - Eliminar mi cuenta</h1>
        <hr></hr>

        <p>
          Para eliminar definitivamente tu cuenta de Carnaval App, debes
          solicitarlo desde la propia aplicación, enviándonos un mensaje desde
          la sección "Ayudanos a mejorar" que encontrarás en tu perfil.
          Solamente deberás especificar en el mensaje tu intención de eliminar
          tu cuenta de Carnaval App y se eliminará tu cuenta y todos tus datos
          personales en un plazo máximo de 30 días.
        </p>
      </div>
    </div>
  );
};

export default EliminarCuenta;
