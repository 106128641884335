import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { TextField, Button, Switch, Select, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { AddCircleOutline } from "@mui/icons-material";
import {
  COMPARSAS,
  HUMORISTAS,
  MURGAS,
  PARODISTAS,
  REVISTAS,
  estadoConcursoDescripcion,
  estadosConcurso
} from "../utils/constants";

import { ConjuntoConcursoListItem } from "./ConjuntoConcursoListItem";
import { showErrorMessage, showSuccessMessage } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BiCalendarAlt, BiErrorAlt } from "react-icons/bi";
import { FaFlagCheckered } from "react-icons/fa";
import QuantityInput from "../components/QuantityInput";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import { GiPodium } from "react-icons/gi";
import { BsFillCalculatorFill } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { formatearFechaHoraYYYYMMDDHHMMSS } from "../utils/dates";

export const Concursos = () => {
  const [conjuntos, setConjuntos] = useState([]);
  const [Concursos, setConcursos] = useState([]);
  const [agregando, setAgregando] = useState(false);
  const [nombre, setNombre] = useState("");
  const [fechaHoraCierrePenca, setFechaHoraCierrePenca] = useState(new Date());
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [cantidadMurgas, setCantidadMurgas] = useState(21);
  const [cantidadParodistas, setCantidadParodistas] = useState(5);
  const [cantidadHumoristas, setCantidadHumoristas] = useState(4);
  const [cantidadRevistas, setCantidadRevistas] = useState(4);
  const [cantidadComparsas, setCantidadComparsas] = useState(5);
  const [cantidadMurgasLiguilla, setCantidadMurgasLiguilla] = useState(10);
  const [cantidadParodistasLiguilla, setCantidadParodistasLiguilla] = useState(
    4
  );
  const [cantidadHumoristasLiguilla, setCantidadHumoristasLiguilla] = useState(
    3
  );
  const [cantidadRevistasLiguilla, setCantidadRevistasLiguilla] = useState(3);
  const [cantidadComparsasLiguilla, setCantidadComparsasLiguilla] = useState(4);
  const [murgas, setMurgas] = useState([]);
  const [parodistas, setParodistas] = useState([]);
  const [humoristas, setHumoristas] = useState([]);
  const [revistas, setRevistas] = useState([]);
  const [comparsas, setComparsas] = useState([]);
  const [textoBusqueda, setTextoBusqueda] = useState("");
  const [cantidadEtapasPrimeraRueda, setCantidadEtapasPrimeraRueda] = useState(
    10
  );
  const [cantidadEtapasSegundaRueda, setCantidadEtapasSegundaRueda] = useState(
    10
  );
  const [cantidadEtapasLiguilla, setCantidadEtapasLiguilla] = useState(8);
  const sendRequest = useRequestHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [estado, setEstado] = useState(1);
  const refrescarGrilla = () => {
    sendRequest(Servicios.ObtenerConcursos, [], data => {
      setConcursos(data?.data);
    });
  };

  useEffect(() => {
    if (agregando || editando) {
      sendRequest(Servicios.ConjuntosConcursan, [textoBusqueda, idEditando], data => {
        setConjuntos(
          data?.data.filter(
            z =>
              !murgas
                ?.concat(parodistas)
                ?.concat(humoristas)
                ?.concat(revistas)
                ?.concat(comparsas)
                .map(x => x.id)
                .includes(z.id)
          )
        );
      });
    }
  }, [
    agregando,
    editando,
    textoBusqueda,
    murgas,
    parodistas,
    humoristas,
    revistas,
    comparsas
  ]);

  useEffect(() => {
    refrescarGrilla();
  }, []);

  const eliminarConcurso = id => {
    sendRequest(Servicios.EliminarConcurso, [id], refrescarGrilla);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 5 },
    { field: "nombre", headerName: "Nombre", flex: 20 },
    { field: "anio", headerName: "Año", flex: 10 },
    {
      field: "concursoEstadoId",
      headerName: "Estado",
      flex: 20,
      renderCell: params => {
        return <>{estadoConcursoDescripcion(params.row.concursoEstadoId)}</>;
      }
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      flex: 5,
      align: "right",
      renderCell: params => {
        if (params.row.concursoEstadoId == estadosConcurso.segundaRueda)
          return (
            <>
              <FaFlagCheckered
                size={26}
                className="action-icon"
                onClick={() =>
                  navigate(`/backoffice/liguilla/${params?.row?.id}`)
                }
                title={"Liguilla"}
              />
            </>
          );
        if (params.row.concursoEstadoId == estadosConcurso.nocheFallos)
          return (
            <>
              <GiPodium
                size={30}
                className="action-icon"
                onClick={() =>
                  navigate(`/backoffice/resultados/${params?.row?.id}`)
                }
                title={"Resultados"}
              />
            </>
          );
      }
    },
    {
      field: "actionCalc",
      headerName: "",
      sortable: false,
      flex: 5,
      align: "right",
      renderCell: params => {
        if (params.row.concursoEstadoId == estadosConcurso.nocheFallos)
          return (
            <>
              <BsFillCalculatorFill
                size={26}
                className="action-icon"
                onClick={() =>
                  sendRequest(
                    Servicios.CalcularResultadosPencas,
                    [params.row.id],
                    () => {
                      dispatch(
                        showSuccessMessage("Los resultados están listos!")
                      );
                    }
                  )
                }
                title={"Calcular resultados de pencas"}
              />
            </>
          );
        return <></>;
      }
    },
    {
      field: "actionError",
      headerName: "",
      sortable: false,
      flex: 5,
      align: "right",
      renderCell: params => {
        return params?.row?.cantidadMurgas >
          params?.row?.conjuntos?.filter(x => x.categoria == MURGAS).length ||
          params?.row?.cantidadParodistas >
            params?.row?.conjuntos?.filter(x => x.categoria == PARODISTAS)
              .length ||
          params?.row?.cantidadHumoristas >
            params?.row?.conjuntos?.filter(x => x.categoria == HUMORISTAS)
              .length ||
          params?.row?.cantidadRevistas >
            params?.row?.conjuntos?.filter(x => x.categoria == REVISTAS)
              .length ||
          params?.row?.cantidadComparsas >
            params?.row?.conjuntos?.filter(x => x.categoria == COMPARSAS)
              .length ? (
          <BiErrorAlt
            color="red"
            size={28}
            className="action-icon"
            title="FALTAN CONJUNTOS"
            onClick={() => editarConcurso(params)}
          />
        ) : (
          <></>
        );
      }
    },
    {
      field: "actionEtapas",
      headerName: "",
      sortable: false,
      minWidth: 5,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          navigate(`/backoffice/etapas-concurso/${params?.row?.id}`);
        };

        return (
          <BiCalendarAlt
            size={28}
            className="action-icon"
            title="Etapas"
            onClick={onClick}
          />
        );
      }
    },
    {
      field: "actionEstado",
      headerName: "",
      sortable: false,
      minWidth: 5,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          navigate(
            `/backoffice/concurso/${params?.row?.id}/cambiarEstado/${params.row.concursoEstadoId}`
          );
        };

        return (
          <FiSettings
            size={28}
            className="action-icon"
            title="Estado"
            onClick={onClick}
          />
        );
      }
    },
    {
      field: "actionEdit",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          editarConcurso(params);
        };

        return <EditIcon className="action-icon" onClick={onClick} />;
      }
    },
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar el Concurso?")) {
            eliminarConcurso(params?.row?.id);
          }
        };

        return <DeleteIcon className="action-icon" onClick={onClick} />;
      }
    }
  ];

  const editarConcurso = params => {
    setAgregando(true);
    setEditando(true);
    setNombre(params.row.nombre);
    setAnio(params.row.anio);
    setIdEditando(params.row.id);
    setMurgas(params.row.conjuntos.filter(x => x.categoria == MURGAS));
    setParodistas(params.row.conjuntos.filter(x => x.categoria == PARODISTAS));
    setHumoristas(params.row.conjuntos.filter(x => x.categoria == HUMORISTAS));
    setRevistas(params.row.conjuntos.filter(x => x.categoria == REVISTAS));
    setComparsas(params.row.conjuntos.filter(x => x.categoria == COMPARSAS));
    setCantidadMurgas(params.row.cantidadMurgas);
    setCantidadParodistas(params.row.cantidadParodistas);
    setCantidadHumoristas(params.row.cantidadHumoristas);
    setCantidadRevistas(params.row.cantidadRevistas);
    setCantidadComparsas(params.row.cantidadComparsas);
    setCantidadMurgasLiguilla(params.row.cantidadMurgasLiguilla);
    setCantidadParodistasLiguilla(params.row.cantidadParodistasLiguilla);
    setCantidadHumoristasLiguilla(params.row.cantidadHumoristasLiguilla);
    setCantidadRevistasLiguilla(params.row.cantidadRevistasLiguilla);
    setCantidadComparsasLiguilla(params.row.cantidadComparsasLiguilla);
    setCantidadEtapasPrimeraRueda(params.row.cantidadEtapasPrimeraRueda);
    setCantidadEtapasSegundaRueda(params.row.cantidadEtapasSegundaRueda);
    setCantidadEtapasLiguilla(params.row.cantidadEtapasLiguilla);
    setFechaHoraCierrePenca(
      new Date(params.row.fechaHoraCierrePenca) ?? new Date()
    );
    setEstado(params.row.concursoEstadoId);
  };

  const columnsConjuntos = [
    { field: "nombre", headerName: "Nombre", flex: 99 },
    {
      field: "actionAdd",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          let agregado = false;
          switch (params?.row?.categoria) {
            case MURGAS:
              if (murgas?.length < cantidadMurgas) {
                setMurgas([...murgas, params.row]);
                agregado = true;
              } else {
                dispatch(showErrorMessage("Máximo alcanzado"));
              }
              break;
            case PARODISTAS:
              if (parodistas?.length < cantidadParodistas) {
                setParodistas([...parodistas, params.row]);
                agregado = true;
              } else {
                dispatch(showErrorMessage("Máximo alcanzado"));
              }
              break;
            case REVISTAS:
              if (revistas?.length < cantidadRevistas) {
                setRevistas([...revistas, params.row]);
                agregado = true;
              } else {
                dispatch(showErrorMessage("Máximo alcanzado"));
              }
              break;
            case HUMORISTAS:
              if (humoristas?.length < cantidadHumoristas) {
                setHumoristas([...humoristas, params.row]);
                agregado = true;
              } else {
                dispatch(showErrorMessage("Máximo alcanzado"));
              }
              break;
            case COMPARSAS:
              if (comparsas?.length < cantidadComparsas) {
                setComparsas([...comparsas, params.row]);
                agregado = true;
              } else {
                dispatch(showErrorMessage("Máximo alcanzado"));
              }
              break;
            default:
              agregado = false;
              break;
          }

          setTextoBusqueda("");
        };

        return <AddCircleOutline className="action-icon" onClick={onClick} />;
      }
    }
  ];

  const limpiarForm = () => {
    setNombre("");
    setAnio("");
    setCantidadMurgas(21);
    setCantidadParodistas(5);
    setCantidadComparsas(5);
    setCantidadRevistas(4);
    setCantidadHumoristas(4);
    setCantidadMurgasLiguilla(10);
    setCantidadParodistasLiguilla(4);
    setCantidadComparsasLiguilla(4);
    setCantidadRevistasLiguilla(3);
    setCantidadHumoristasLiguilla(3);
    setMurgas([]);
    setParodistas([]);
    setHumoristas([]);
    setRevistas([]);
    setComparsas([]);
    setConjuntos([]);
  };

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        Servicios.ModificarConcurso,
        [
          idEditando,
          {
            id: idEditando,
            nombre,
            anio,
            cantidadMurgas,
            cantidadParodistas,
            cantidadHumoristas,
            cantidadRevistas,
            cantidadComparsas,
            cantidadMurgasLiguilla,
            cantidadParodistasLiguilla,
            cantidadHumoristasLiguilla,
            cantidadRevistasLiguilla,
            cantidadComparsasLiguilla,
            cantidadEtapasPrimeraRueda,
            cantidadEtapasSegundaRueda,
            cantidadEtapasLiguilla,
            murgas: murgas.map(x => x.id),
            parodistas: parodistas.map(x => x.id),
            humoristas: humoristas.map(x => x.id),
            revistas: revistas.map(x => x.id),
            comparsas: comparsas.map(x => x.id),
            fechaHoraCierrePenca: formatearFechaHoraYYYYMMDDHHMMSS(
              fechaHoraCierrePenca
            ),
            concursoEstadoId: estado
          }
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          dispatch(showSuccessMessage("Concurso modificado correctamente"));
          refrescarGrilla();
        }
      );
    } else {
      sendRequest(
        Servicios.CrearConcurso,
        [
          {
            nombre,
            anio,
            cantidadMurgas,
            cantidadParodistas,
            cantidadHumoristas,
            cantidadRevistas,
            cantidadComparsas,
            cantidadMurgasLiguilla,
            cantidadParodistasLiguilla,
            cantidadHumoristasLiguilla,
            cantidadRevistasLiguilla,
            cantidadComparsasLiguilla,
            cantidadEtapasPrimeraRueda,
            cantidadEtapasSegundaRueda,
            cantidadEtapasLiguilla,
            murgas: murgas.map(x => x.id),
            parodistas: parodistas.map(x => x.id),
            humoristas: humoristas.map(x => x.id),
            revistas: revistas.map(x => x.id),
            comparsas: comparsas.map(x => x.id),
            concursoEstadoId: estado
          }
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          dispatch(showSuccessMessage("Concurso creado correctamente"));
          refrescarGrilla();
        }
      );
    }
  };

  const handleDateTimeChange = newDateTime => {
    setFechaHoraCierrePenca(newDateTime);
  };

  const handleTimeChange = newTime => {
    const [hours, minutes] = newTime.split(":");
    const updatedDateTime = new Date(fechaHoraCierrePenca);
    updatedDateTime.setHours(Number(hours));
    updatedDateTime.setMinutes(Number(minutes));
    setFechaHoraCierrePenca(updatedDateTime);
  };

  return (
    <>
      <>
        {!agregando ? (
          <>
            <div className="d-flex row m-2 justify-content-between">
              <div className="col-4">
                <h4>Listado de Concursos</h4>
              </div>

              <div>
                <Button
                  variant={"contained"}
                  onClick={() => setAgregando(true)}
                >
                  Agregar
                </Button>
              </div>
            </div>
            <hr></hr>
            <div className="row m-2 justify-content-center">
              {Concursos?.length > 0 ? (
                <DataGrid
                  rows={Concursos}
                  columns={columns}
                  paginationModel={{ page: 0, pageSize: 50 }}
                  checkboxSelection={false}
                />
              ) : (
                <h5 className="">No se encontraron resultados</h5>
              )}
            </div>
          </>
        ) : (
          <div className="justify-content-around ">
            <div className="d-flex row m-2 justify-content-between">
              <div className="col">
                <h4>{editando ? "Modificar Concurso" : "Nuevo Concurso"}</h4>
              </div>
            </div>
            <hr></hr>
            <div className="justify-content-center w-auto d-flex row">
              <div className="col ">
                <h5 className="text-center">Datos generales</h5>
                <hr></hr>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Nombre"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                  />
                </div>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Año"
                    value={anio}
                    onChange={e => setAnio(e.target.value)}
                  />
                </div>
                <div className="col m-2">
                  <Select
                    size="small"
                    placeholder="Estado"
                    className="w-100"
                    value={estado}
                    onChange={event => setEstado(event.target.value)}
                  >
                    <MenuItem value={estadosConcurso.primeraRueda}>
                      Primera rueda
                    </MenuItem>
                    <MenuItem value={estadosConcurso.segundaRueda}>
                      Segunda rueda
                    </MenuItem>
                    <MenuItem value={estadosConcurso.liguilla}>
                      Liguilla
                    </MenuItem>
                    <MenuItem value={estadosConcurso.nocheFallos}>
                      Noche de fallos
                    </MenuItem>
                    <MenuItem value={estadosConcurso.resultadosDisponibles}>
                      Resultados disponibles
                    </MenuItem>
                  </Select>
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    className="w-100"
                    size="small"
                    label="Etapas en primera rueda"
                    value={cantidadEtapasPrimeraRueda}
                    onChange={e => setCantidadEtapasPrimeraRueda(e)}
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    value={cantidadEtapasSegundaRueda}
                    onChange={e => setCantidadEtapasSegundaRueda(e)}
                    label="Etapas en segunda rueda"
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    className="w-100"
                    size="small"
                    label="Etapas en liguilla"
                    value={cantidadEtapasLiguilla}
                    onChange={e => setCantidadEtapasLiguilla(e)}
                  />
                </div>
                <div className="col m-2">
                  <hr></hr>
                  <h5>Cierre de pencas:</h5>
                  <br></br>
                  <DatePicker
                    selected={fechaHoraCierrePenca}
                    onChange={handleDateTimeChange}
                    dateFormat="dd/MM/yyyy"
                    todayButton="Today"
                    showYearDropdown
                  />
                  <TimePicker
                    value={fechaHoraCierrePenca}
                    onChange={handleTimeChange}
                    disableClock
                  />
                  <hr></hr>
                </div>
              </div>

              <div className="col  text-right">
                <h5 className="text-center">Primera y segunda rueda</h5>
                <hr></hr>

                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    className="w-50"
                    size="small"
                    label="Murgas"
                    value={cantidadMurgas}
                    onChange={e => setCantidadMurgas(e)}
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    className="w-50"
                    size="small"
                    label="Parodistas"
                    value={cantidadParodistas}
                    onChange={e => setCantidadParodistas(e)}
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    className="w-50"
                    size="small"
                    label="Humoristas"
                    value={cantidadHumoristas}
                    onChange={e => setCantidadHumoristas(e)}
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    className="w-50"
                    size="small"
                    label="Revistas"
                    value={cantidadRevistas}
                    onChange={e => setCantidadRevistas(e)}
                  />
                </div>

                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    className="w-50"
                    size="small"
                    label="Comparsas"
                    value={cantidadComparsas}
                    onChange={e => setCantidadComparsas(e)}
                  />
                </div>
              </div>
              <div className="col text-center">
                <h5 className="text-center">Liguilla</h5>
                <hr></hr>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    maxValue={cantidadMurgas}
                    className="w-50"
                    size="small"
                    label="Murgas"
                    value={cantidadMurgasLiguilla}
                    onChange={e => setCantidadMurgasLiguilla(e)}
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    maxValue={cantidadParodistas}
                    className="w-50"
                    size="small"
                    label="Parodistas"
                    value={cantidadParodistasLiguilla}
                    onChange={e => setCantidadParodistasLiguilla(e)}
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    maxValue={cantidadHumoristas}
                    className="w-50"
                    size="small"
                    label="Humoristas"
                    value={cantidadHumoristasLiguilla}
                    onChange={e => setCantidadHumoristasLiguilla(e)}
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    maxValue={cantidadRevistas}
                    className="w-50"
                    size="small"
                    label="Revistas"
                    value={cantidadRevistasLiguilla}
                    onChange={e => setCantidadRevistasLiguilla(e)}
                  />
                </div>
                <div className="col m-2">
                  <QuantityInput
                    minValue={0}
                    maxValue={cantidadComparsas}
                    className="w-50"
                    size="small"
                    label="Comparsas"
                    value={cantidadComparsasLiguilla}
                    onChange={e => setCantidadComparsasLiguilla(e)}
                  />
                </div>
              </div>
            </div>

            <hr></hr>
            <div className="d-flex row justify-content-around">
              <div className="col-3">
                <TextField
                  className="w-100 my-1"
                  size="small"
                  label="Buscar conjunto"
                  value={textoBusqueda}
                  onChange={e => setTextoBusqueda(e.target.value)}
                />

                <DataGrid
                  style={{ maxHeight: 400, overflow: "auto" }}
                  pagination={false}
                  disableColumnFilter
                  disableColumnMenu
                  disableDensitySelector
                  hideFooterPagination
                  rows={conjuntos.filter(x => !x.agregado)}
                  columns={columnsConjuntos}
                  checkboxSelection={false}
                  className="no-header"
                />
              </div>
              <div className="col-3 text-center listado-conjunto-container">
                <div>
                  <h5>{`Murgas (${murgas?.length} de ${cantidadMurgas})`}</h5>
                  <progress
                    className="col-8"
                    max={cantidadMurgas}
                    value={murgas?.length}
                  ></progress>
                  <ConjuntoConcursoListItem
                    conjuntosCategoria={murgas}
                    conjuntos={conjuntos}
                    setterCategoria={setMurgas}
                    setConjuntos={setConjuntos}
                  ></ConjuntoConcursoListItem>
                </div>
              </div>
              <div className="col-3 text-left">
                <div className=" listado-conjunto-container">
                  <h5>{`Parodistas (${parodistas?.length} de ${cantidadParodistas})`}</h5>
                  <progress
                    max={cantidadParodistas}
                    value={parodistas?.length}
                  ></progress>
                  <ConjuntoConcursoListItem
                    conjuntosCategoria={parodistas}
                    conjuntos={conjuntos}
                    setterCategoria={setParodistas}
                    setConjuntos={setConjuntos}
                  ></ConjuntoConcursoListItem>
                </div>
                <div className=" listado-conjunto-container">
                  <h5>{`Revistas (${revistas?.length} de ${cantidadRevistas})`}</h5>
                  <progress
                    max={cantidadRevistas}
                    value={revistas?.length}
                  ></progress>
                  <ConjuntoConcursoListItem
                    conjuntosCategoria={revistas}
                    conjuntos={conjuntos}
                    setterCategoria={setRevistas}
                    setConjuntos={setConjuntos}
                  ></ConjuntoConcursoListItem>
                </div>
              </div>
              <div className="col-3 text-left">
                <div className=" listado-conjunto-container">
                  <h5>{`Humoristas (${humoristas?.length} de ${cantidadHumoristas})`}</h5>
                  <progress
                    max={cantidadHumoristas}
                    value={humoristas?.length}
                  ></progress>
                  <ConjuntoConcursoListItem
                    conjuntosCategoria={humoristas}
                    conjuntos={conjuntos}
                    setterCategoria={setHumoristas}
                    setConjuntos={setConjuntos}
                  ></ConjuntoConcursoListItem>
                </div>
                <div className=" listado-conjunto-container ">
                  <h5>{`Comparsas (${comparsas?.length} de ${cantidadComparsas})`}</h5>
                  <progress
                    max={cantidadComparsas}
                    value={comparsas?.length}
                  ></progress>
                  <ConjuntoConcursoListItem
                    conjuntosCategoria={comparsas}
                    conjuntos={conjuntos}
                    setterCategoria={setComparsas}
                    setConjuntos={setConjuntos}
                  ></ConjuntoConcursoListItem>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    setEditando(false);
                    setAgregando(false);
                    limpiarForm();
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default withAuthenticationRequired(Concursos, {
  onRedirecting: () => <Loading />
});
