import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import {
  TextField,
  Button,
  Autocomplete,
  Box,
  Slider,
  Switch,
  Select,
  MenuItem
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { showErrorMessage } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import DatePicker from "react-multi-date-picker";
import { formatDate } from "../utils/dates";
import dayjs from "dayjs";
import { MdClear } from "react-icons/md";

export const Banner = () => {
  const defaultDate = dayjs(new Date().toString());
  const [banners, setBanners] = useState([]);
  const [anunciantes, setAnunciantes] = useState([]);
  const [agregando, setAgregando] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [link, setLink] = useState("");
  const [anuncianteText, setAnuncianteText] = useState("");
  const [anuncianteSeleccionado, setAnuncianteSeleccionado] = useState({});
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const [imagen, setImagen] = useState();
  const [imagenModal, setImagenModal] = useState();
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [imgModalPreviewSrc, setImgModalPreviewSrc] = useState("");
  const [activo, setActivo] = useState(false);
  const [incluirInactivos, setIncluirInactivos] = useState(false);
  const [tipoBanner, setTipoBanner] = useState(1);
  const sendRequest = useRequestHandler();
  const [fechaDesde, setFechaDesde] = useState(defaultDate);
  const [fechaHasta, setFechaHasta] = useState();

  const refrescarGrilla = () => {
    sendRequest(Servicios.ObtenerBanners, [incluirInactivos], data => {
      setBanners(data?.data);
    });
  };
  const dispatch = useDispatch();

  const limpiarForm = () => {
    setDescripcion("");
    setImagen({});
    setImagenModal({});
    setImgPreviewSrc("");
    setImgModalPreviewSrc("");
  };

  useEffect(() => {
    refrescarGrilla();
    obtenerAnunciantes();
  }, [incluirInactivos]);

  const obtenerAnunciantes = () => {
    sendRequest(Servicios.ObtenerAnunciantes, [], data => {
      setAnunciantes(data?.data);
    });
  };

  const eliminarBanner = id => {
    sendRequest(Servicios.EliminarBanner, [id], refrescarGrilla);
  };

  const handleTipoBannerChange = event => {
    setTipoBanner(event.target.value);
  };

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "anuncianteNombre", headerName: "Anunciante" },
    { field: "tipoBannerId", headerName: "Tipo" },
    {
      field: "link",
      headerName: "Link",
      flex: 50,
      renderCell: params => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${params?.row?.link}`}
          >
            {params?.row?.link}
          </a>
        );
      }
    },
    { field: "peso", headerName: "Peso" },
    {
      field: "imagenPath",
      headerName: "Imagen",
      flex: 50,
      renderCell: params => {
        return (
          <img
            style={{ width: "250px", height: "60px" }}
            src={params?.row?.imagenPath}
          ></img>
        );
      }
    },
    {
      field: "actionEdit",
      headerName: "",
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setIdEditando(params.row.id);
          setDescripcion(params.row.descripcion);
          setIntercalado(params.row.peso);
          setLink(params.row.link);
          let anuncianteTmp = anunciantes.filter(
            x => x.id == params.row.anuncianteId
          )?.[0];
          setActivo(params.row.activo);
          setAnuncianteSeleccionado(anuncianteTmp);
          setAnuncianteText(anuncianteTmp?.nombre);
          setImgPreviewSrc(params.row.imagenPath);
          setImgModalPreviewSrc(params.row.modalImagenPath);
          setTipoBanner(params.row.tipoBannerId);
          setFechaDesde(dayjs(params.row.fechaDesde));
          setFechaHasta(
            params.row.fechaHasta ? dayjs(params.row.fechaHasta) : null
          );
        };

        return <EditIcon className="action-icon" onClick={onClick} />;
      }
    },
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar el Banner?")) {
            eliminarBanner(params?.row?.id);
          }
          refrescarGrilla();
        };

        return <DeleteIcon className="action-icon" onClick={onClick} />;
      }
    }
  ];

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        Servicios.ModificarBanner,
        [
          {
            id: idEditando,
            anuncianteId: anuncianteSeleccionado.id,
            descripcion: descripcion,
            link: link,
            peso: intercalado,
            activo: activo,
            tipoBannerId: tipoBanner,
            fechaDesde,
            fechaHasta
          },
          imagen,
          imagenModal
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    } else {
      sendRequest(
        Servicios.CrearBanner,
        [
          {
            anuncianteId: anuncianteSeleccionado.id,
            descripcion: descripcion,
            link: link,
            peso: intercalado,
            activo: activo,
            tipoBannerId: tipoBanner,
            fechaDesde,
            fechaHasta
          },
          imagen,
          imagenModal
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    }
  };

  const handleChange = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jfif|\.jpeg|\.png|\.gif|\.svg)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      setImagen({});
      return false;
    }
    setImagen(file);
    var reader = new FileReader();
    reader.onload = function(e) {
      setImgPreviewSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleChangeModal = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jfif|\.jpeg|\.png|\.gif|\.svg)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      setImagenModal({});
      return false;
    }
    setImagenModal(file);
    var reader = new FileReader();
    reader.onload = function(e) {
      setImgModalPreviewSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const [intercalado, setIntercalado] = useState(3);

  function valuetext(value) {
    return `${value}`;
  }

  const handleIntercaladoChange = event => {
    setIntercalado(event.target.value);
  };

  return (
    <>
      <>
        {!agregando ? (
          <>
            <div className="d-flex row m-2 justify-content-between">
              <div className="col">
                <h4> Banner publicitarios</h4>
              </div>
              <div className="col-auto m-2">
                <Switch
                  checked={incluirInactivos}
                  onChange={e => setIncluirInactivos(!incluirInactivos)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                Incluir inactivos
              </div>
              <div>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    setAgregando(true);

                    setIdEditando(0);
                    setDescripcion("");
                    setIntercalado(4);
                    setLink("");
                    setActivo(true);
                    setAnuncianteSeleccionado({});
                    setAnuncianteText("");
                    setImgPreviewSrc("");
                    setImgModalPreviewSrc("");
                  }}
                >
                  Agregar
                </Button>
              </div>
            </div>
            <hr></hr>
            <div
              className="row m-2 justify-content-center"
              style={{ width: "100%" }}
            >
              {banners?.length > 0 ? (
                <DataGrid
                  rows={banners}
                  columns={columns}
                  checkboxSelection={false}
                />
              ) : (
                <h5 className="">No se encontraron resultados</h5>
              )}
            </div>
          </>
        ) : (
          <div className="justify-content-around ">
            <div className="justify-content-center w-auto d-flex row">
              <div className="col">
                <div className="d-flex row justify-content-between">
                  <div className="col m-2">
                    <h4>{editando ? "Modificar Banner" : "Nuevo Banner"}</h4>
                  </div>
                </div>
                <div className="col m-2">
                  <Autocomplete
                    disabled={editando}
                    defaultValue={anuncianteSeleccionado?.nombre}
                    onChange={(event, newValue) => {
                      setAnuncianteSeleccionado(newValue);
                    }}
                    options={anunciantes}
                    getOptionLabel={option =>
                      option.nombre ?? anuncianteSeleccionado?.nombre
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        value={anuncianteText}
                        onChange={e => setAnuncianteText(e.target.value)}
                        label="Seleccionar anunciante"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className="col m-2">
                  <Select value={tipoBanner} onChange={handleTipoBannerChange}>
                    <MenuItem value={1}>Tipo 1 - Rectangular tablados</MenuItem>
                    <MenuItem value={2}>Tipo 2 - Cuadrado tablados</MenuItem>
                    <MenuItem value={3}>
                      Tipo 3 - Rectangular detalle tablado
                    </MenuItem>
                  </Select>
                </div>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Descripción del banner"
                    value={descripcion}
                    onChange={e => setDescripcion(e.target.value)}
                  />
                </div>
                <div className="col mt-3 mx-2">
                  <h6>Intercalado</h6>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      aria-label="Always visible"
                      defaultValue={3}
                      getAriaValueText={valuetext}
                      value={intercalado}
                      onChange={handleIntercaladoChange}
                      step={1}
                      min={1}
                      max={3}
                      valueLabelDisplay="on"
                    />
                  </Box>
                </div>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Link"
                    value={link}
                    onChange={e => setLink(e.target.value)}
                  />
                </div>
                <div className="col m-2">
                  <Switch
                    label="Activo"
                    checked={activo}
                    onChange={e => setActivo(!activo)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  Activo
                </div>
                <div className="col m-2">
                  <DatePicker
                    label="Desde"
                    format="DD/MM/YYYY"
                    value={fechaDesde}
                    onChange={setFechaDesde}
                    slotProps={{
                      textField: {
                        size: "small",
                        inputProps: { value: formatDate(new Date(fechaDesde)) }
                      }
                    }}
                  />
                </div>
                <div className="col m-2">
                  <DatePicker
                    defaultValue={null}
                    label="Hasta"
                    format="DD/MM/YYYY"
                    value={fechaHasta}
                    onChange={setFechaHasta}
                    slotProps={{
                      textField: {
                        size: "small",
                        inputProps: {
                          value: fechaHasta
                            ? formatDate(new Date(fechaHasta))
                            : ""
                        }
                      }
                    }}
                  />
                  <Button
                    className="btn btn-secondary m-2"
                    variant="outlined"
                    onClick={() => setFechaHasta(null)}
                  >
                    <MdClear />
                  </Button>
                </div>
              </div>
              <div className="col">
                <div className="col file-upload ml-4">
                  <div className="row px-4">
                    <label
                      className="col d-flex justify-content-between"
                      htmlFor="upload-photo"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={handleChange}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        component="span"
                      >
                        Cargar banner
                      </Button>
                      <span className="m-2">{imagen?.name}</span>
                    </label>
                  </div>
                  {imgPreviewSrc && (
                    <div id="imagePreview" className="col text-center">
                      <img src={imgPreviewSrc} className="img-preview"></img>
                    </div>
                  )}
                </div>
                <div className="col file-upload ml-4">
                  <div className="row px-4">
                    <label
                      className="col d-flex justify-content-between"
                      htmlFor="upload-photo-modal"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo-modal"
                        name="upload-photo-modal"
                        type="file"
                        onChange={handleChangeModal}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        component="span"
                      >
                        Cargar modal
                      </Button>
                      <span className="m-2">{imagen?.name}</span>
                    </label>
                  </div>
                  {imgModalPreviewSrc && (
                    <div id="imagePreviewModal" className="col text-center">
                      <img
                        src={imgModalPreviewSrc}
                        className="img-preview-modal"
                      ></img>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    limpiarForm();
                    setEditando(false);
                    setAgregando(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default withAuthenticationRequired(Banner, {
  onRedirecting: () => <Loading />
});
