import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import {
  MenuItem,
  Select,
  TextField,
  Button,
  FormControlLabel,
  Switch
} from "@mui/material";
import { IoDice } from "react-icons/io5";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { showErrorMessage } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useRequestHandler } from "../utils/useRequestHandler";
import { useStateWithCallback } from "../utils/useStateWithCallback";
import { useTypeThenRun } from "../utils/useTypeThenRun";
import { useNavigate } from "react-router-dom";
import Servicios from "../services";
import { GiPodium } from "react-icons/gi";
import { useEffectSinEjecucionInicial } from "../utils/useEffectSinEjecucionInicial";

export const Pencas = () => {
  const [pencas, setPencas] = useState([]);
  const [agregando, setAgregando] = useState(false);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [descripcionPremio, setDescripcionPremio] = useState("");
  const [concursos, setConcursos] = useStateWithCallback([]);
  const [concursoId, setConcursoId] = useState(0);
  const [concursoActualId, setConcursoActualId] = useState(0);
  const [esPrivada, setEsPrivada] = useState(true);
  const [clave, setClave] = useState("");
  const [esPatrocinada, setEsPatrocinada] = useState(false);
  const [tienePremio, setTienePremio] = useState(false);
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);

  const [imagen, setImagen] = useState();
  const [imagenPremio, setimagenPremio] = useState();
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [imgPremioPreviewSrc, setimgPremioPreviewSrc] = useState("");
  const [textoBusqueda, setTextoBusqueda] = useState("");
  const navigate = useNavigate();
  const sendRequest = useRequestHandler();
  const dispatch = useDispatch();

  const refrescarGrilla = () => {
    sendRequest(Servicios.ObtenerPencas, [textoBusqueda], data => {
      setPencas(data?.data);
    });
  };

  const limpiarForm = () => {
    setNombre("");
    setImagen({});
    setimagenPremio({});
    setImgPreviewSrc("");
    setimgPremioPreviewSrc("");
    setDescripcion("");
    setConcursoId(concursoActualId);
    setEsPrivada(true);
    setClave("");
    setEsPatrocinada(false);
    setDescripcionPremio("");
    setTienePremio(false);
  };

  useEffect(() => {
    refrescarGrilla();
  }, []);

  useEffectSinEjecucionInicial(() => {
    if (esPatrocinada) {
      setEsPrivada(false);
      setTienePremio(true);
    }
  }, [esPatrocinada]);

  useEffectSinEjecucionInicial(() => {
    if (!tienePremio) {
      setEsPatrocinada(false);
    }
  }, [tienePremio]);

  useTypeThenRun(textoBusqueda, refrescarGrilla);

  const eliminarPenca = id => {
    sendRequest(Servicios.EliminarPenca, [id], refrescarGrilla);
  };

  useEffect(() => {
    if (agregando) {
      sendRequest(Servicios.ObtenerConcursos, [], data => {
        setConcursos(data?.data, () => {
          sendRequest(Servicios.ObtenerConcursoActual, [], data2 => {
            if (!editando) setConcursoId(data2.data.id);
            setConcursoActualId(data2.data.id);
          });
        });
      });
    }
  }, [agregando]);

  const columns = [
    { field: "id", imgPremioPreviewSrcName: "ID", flex: 10 },
    { field: "nombre", imgPremioPreviewSrcName: "Nombre", flex: 80 },
    {
      field: "actionResultados",
      imgPremioPreviewSrcName: "",
      sortable: false,
      flex: 10,
      align: "right",
      renderCell: params => {
        return (
          <>
            <GiPodium
              size={30}
              className="action-icon"
              onClick={() =>
                navigate(`/backoffice/resultados-penca/${params?.row?.id}`)
              }
              title={"Resultados"}
            />
          </>
        );
      }
    },
    {
      field: "actionPredict",
      imgPremioPreviewSrcName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          navigate(`/backoffice/pronosticar/${params?.row?.id}`);
        };

        return (
          <IoDice
            size={28}
            className="action-icon"
            title="Pronosticar"
            onClick={onClick}
          />
        );
      }
    },
    {
      field: "actionEdit",
      imgPremioPreviewSrcName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setIdEditando(params.row.id);

          sendRequest(Servicios.ObtenerPencaPorId, [params.row.id], data => {
            setNombre(data.data.nombre);
            setDescripcion(data.data.descripcion);
            setConcursoId(data.data.concursoId);
            setEsPrivada(data.data.esPrivada);
            setClave(data.data.clave);
            setEsPatrocinada(!!data.data.clave);
            setImgPreviewSrc(data.data.imagenPath);
            setimgPremioPreviewSrc(data.data.premioImagenPath);
            setTienePremio(data.data.tienePremio);
            setDescripcionPremio(data.data.premioDescripcion);
          });
        };

        return (
          <EditIcon
            size={28}
            className="action-icon"
            title="Editar"
            onClick={onClick}
          />
        );
      }
    },
    {
      field: "actionDelete",
      imgPremioPreviewSrcName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar la penca?")) {
            eliminarPenca(params?.row?.id);
          }
          refrescarGrilla();
        };

        return (
          <DeleteIcon
            size={28}
            className="action-icon"
            title="Eliminar"
            onClick={onClick}
          />
        );
      }
    }
  ];

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        Servicios.ModificarPenca,
        [
          {
            id: idEditando,
            nombre: nombre,
            descripcion: descripcion,
            esPrivada: esPrivada,
            clave: esPatrocinada ? clave : "",
            concursoId: concursoId,
            tienePremio: tienePremio,
            premioDescripcion: descripcionPremio
          },
          imagen,
          imagenPremio
        ],
        data => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    } else {
      sendRequest(
        Servicios.CrearPenca,
        [
          {
            nombre: nombre,
            descripcion: descripcion,
            esPrivada: esPrivada,
            clave: esPatrocinada ? clave : "",
            concursoId: concursoId,
            tienePremio: tienePremio,
            premioDescripcion: descripcionPremio
          },
          imagen,
          imagenPremio
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    }
  };

  const handleChange = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      setImagen({});
      return false;
    }
    setImagen(file);
    var reader = new FileReader();
    reader.onload = function(e) {
      setImgPreviewSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handlePremioChange = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      setimagenPremio({});
      return false;
    }
    setimagenPremio(file);
    var reader = new FileReader();
    reader.onload = function(e) {
      setimgPremioPreviewSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <>
        {!agregando ? (
          <>
            <div className="d-flex row m-2 justify-content-between">
              <div className="col-4">
                <h4>Listado de pencas</h4>
              </div>
              <div className="col">
                <TextField
                  className="w-80"
                  size="small"
                  label="Buscar"
                  value={textoBusqueda}
                  onChange={e => setTextoBusqueda(e.target.value)}
                />
              </div>
              <div>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    setAgregando(true);
                  }}
                >
                  Agregar
                </Button>
              </div>
            </div>
            <hr></hr>
            <div
              className="row m-2 justify-content-center"
              style={{ width: "100%" }}
            >
              {pencas?.length > 0 ? (
                <DataGrid
                  rows={pencas}
                  columns={columns}
                  paginationModel={{ page: 0, pageSize: 50 }}
                  checkboxSelection={false}
                />
              ) : (
                <h5 className="">No se encontraron resultados</h5>
              )}
            </div>
          </>
        ) : (
          <div className="justify-content-around ">
            <div className="d-flex row justify-content-between">
              <div className="col m-2">
                <h4>{editando ? "Modificar penca" : "Nueva penca"}</h4>
              </div>
            </div>
            <hr></hr>
            <div className="justify-content-center w-auto d-flex row">
              <div className="col">
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Nombre"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                  />
                </div>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Descripción"
                    value={descripcion}
                    onChange={e => setDescripcion(e.target.value)}
                    multiline
                    rows={6}
                  />
                </div>
                <div className="col m-2">
                  <Select
                    size="small"
                    className="w-100"
                    placeholder="Concurso"
                    value={concursoId}
                    onChange={e => setConcursoId(e.target.value)}
                  >
                    {concursos?.map(c => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="col">
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={esPatrocinada}
                        checked={esPrivada}
                        onChange={e => setEsPrivada(value => !value)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Es privada"
                    labelPlacement="start"
                  />
                </div>
                <div className="col">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={esPatrocinada}
                        onChange={e => setEsPatrocinada(value => !value)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Es patrocinada"
                    labelPlacement="start"
                  />
                </div>
                <div className="col">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={tienePremio}
                        onChange={e => setTienePremio(value => !value)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Tiene premio"
                    labelPlacement="start"
                  />
                </div>
                <div className="col m-2">
                  {esPatrocinada && (
                    <TextField
                      className="w-100"
                      size="small"
                      label="Clave"
                      value={clave}
                      onChange={e => setClave(e.target.value)}
                    />
                  )}
                </div>
                {tienePremio && (
                  <div className="col m-2">
                    <TextField
                      className="w-100"
                      size="small"
                      label="Descripción del premio"
                      value={descripcionPremio}
                      onChange={e => setDescripcionPremio(e.target.value)}
                      multiline
                      rows={6}
                    />
                  </div>
                )}
              </div>
              <div className="col">
                <div className="col file-upload ">
                  <div className="row px-4">
                    <label
                      className="col d-flex justify-content-between"
                      htmlFor="upload-photo"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={handleChange}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        component="span"
                      >
                        Cargar imagen
                      </Button>
                      <span className="m-2">{imagen?.name}</span>
                    </label>
                  </div>
                  {imgPreviewSrc && (
                    <div id="imagePreview" className="col text-center">
                      <img src={imgPreviewSrc} className="img-preview"></img>
                    </div>
                  )}
                </div>
                {tienePremio && (
                  <div className="col file-upload">
                    <div className="row px-4">
                      <label
                        className="col d-flex justify-content-between"
                        htmlFor="upload-photo-premio"
                      >
                        <input
                          style={{ display: "none" }}
                          id="upload-photo-premio"
                          name="upload-photo-premio"
                          type="file"
                          onChange={handlePremioChange}
                        />
                        <Button
                          color="secondary"
                          variant="contained"
                          component="span"
                        >
                          Imagen Premio
                        </Button>
                        <span className="m-2">{imagenPremio?.name}</span>
                      </label>
                    </div>
                    {imgPremioPreviewSrc && (
                      <div id="imagePremioPreview" className="col text-center">
                        <img
                          src={imgPremioPreviewSrc}
                          className="img-preview"
                        ></img>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    setEditando(false);
                    setAgregando(false);
                    limpiarForm();
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default withAuthenticationRequired(Pencas, {
  onRedirecting: () => <Loading />
});
