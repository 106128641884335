import { formatearFechayyyyMMdd } from "../utils/dates";
import { BackendApi } from "./HttpBaseService";

const ObtenerTablados = async incluirInactivos => {
  return await BackendApi.get(
    `Tablado/Todos?incluirInactivos=${incluirInactivos ?? true}`
  );
};

const CrearTablado = async (tablado, imagen) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  const json = JSON.stringify(tablado);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("tablado", blob);
  return BackendApi.post(`Tablado/Crear`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const EliminarTablado = async id => {
  return await BackendApi.delete(`Tablado/Eliminar?id=${id}`);
};

const ModificarTablado = async (tablado, imagen) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  const json = JSON.stringify(tablado);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("tablado", blob);
  return BackendApi.put(`Tablado/Modificar`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const CargarProgramacionTablado = async programacion => {
  return await BackendApi.post(`Tablado/CargarProgramacion`, programacion);
};

const ObtenerProgramacionTablado = async (tabladoId, fecha) => {
  return await BackendApi.get(
    `Tablado/${tabladoId}/Programacion?fecha=${formatearFechayyyyMMdd(fecha)}`
  );
};

const ObtenerProgramacionTablados = async fecha => {
  return await BackendApi.get(
    `Tablado/ProgramacionPorFecha?fecha=${formatearFechayyyyMMdd(fecha)}`
  );
};

const CambiarActivacionTablado = async (id, activo) => {
  return await BackendApi.put(
    `Tablado/${id}/CambiarActivacion?activo=${activo}`
  );
};

const GenerarProgramacionRandom = async (desde, hasta) => {
  return await BackendApi.post(
    `Tablado/GenerarProgramacionRandom?desde=${formatearFechayyyyMMdd(
      desde
    )}&hasta=${formatearFechayyyyMMdd(hasta)}`
  );
};

const CargaAutomaticaTabladoProgramacion = async (desde, hasta) => {
  return await BackendApi.get(
    `AppUtils/carga-automatica-tablados?desde=${formatearFechayyyyMMdd(
      desde
    )}&hasta=${formatearFechayyyyMMdd(hasta)}`
  );
};

const TabladoService = {
  ObtenerTablados,
  CrearTablado,
  EliminarTablado,
  ModificarTablado,
  CargarProgramacionTablado,
  ObtenerProgramacionTablado,
  ObtenerProgramacionTablados,
  CambiarActivacionTablado,
  GenerarProgramacionRandom,
  CargaAutomaticaTabladoProgramacion
};

export default TabladoService;
