import React from "react";
import loading from "../assets/loading.svg";
import PropagateLoader from "react-spinners/PropagateLoader";

const Loading = () => (
  <div className="center-screen">
    <PropagateLoader
      loading={true}
      size={15}
      aria-label="Loading Spinner"
      data-testid="loader"
      color="#5326cb"
    />
  </div>
);

export default Loading;
