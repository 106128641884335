import { BackendApi } from "./HttpBaseService";

const ObtenerPencas = async nombre => {
  return await BackendApi.get(`Penca/Buscar?nombre=${nombre}`);
};

const EliminarPenca = async id => {
  return await BackendApi.delete(`Penca/${id}`);
};

const ModificarPenca = async (penca, imagen, imagenPremio) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  formData.append("imagenPremio", imagenPremio);
  const json = JSON.stringify(penca);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("penca", blob);
  return BackendApi.put(`Penca/Modificar`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const CrearPenca = async (penca, imagen, imagenPremio) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  formData.append("imagenPremio", imagenPremio);
  const json = JSON.stringify(penca);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("penca", blob);
  return await BackendApi.post(`Penca/Crear`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const ObtenerPencaPorId = async id => {
  return await BackendApi.get(`Penca/${id}`);
};

const CalcularResultadosPencas = async concursoId => {
  return await BackendApi.post(
    `Penca/CalcularResultados?concursoId=${concursoId}`
  );
};

const ObtenerPronostico = async (
  pencaId,
  incluirEliminados = false,
  usuarioId = 0
) => {
  return await BackendApi.get(
    `Penca/${pencaId}/ObtenerPronostico?incluirEliminados=${incluirEliminados}&usuarioId=${usuarioId}`
  );
};

const GuardarPronostico = async (pencaId, pronostico) => {
  return await BackendApi.put(`Penca/${pencaId}/Pronosticar`, pronostico);
};

const ObtenerResultados = async pencaId => {
  return await BackendApi.get(`Penca/${pencaId}/Resultados`);
};

const PencaService = {
  ObtenerPencas,
  EliminarPenca,
  ModificarPenca,
  CrearPenca,
  ObtenerPencaPorId,
  ObtenerPronostico,
  GuardarPronostico,
  ObtenerResultados,
  CalcularResultadosPencas
};

export default PencaService;
