import React, { useEffect, useRef, useState } from "react";
import { Box, MenuItem, Select, Slider } from "@mui/material";
import "./Calculadora.scss";
import ReactPlayer from "react-player";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import { Button, Spinner } from "reactstrap";
import { IoReload } from "react-icons/io5";
import { formatearFechaDDMMYYHHMM } from "../utils/dates";

const CostoPublicidad = () => {
  const [nivelContratacion, setNivelContratacion] = useState("standard");
  const [usuarios, setUsuarios] = useState(9000);

  const calcularCosto = () => {
    return (usuarios / 3).toFixed(0);
  };

  function valuetext(value) {
    return `${value}`;
  }

  const handleUsuariosChange = event => {
    setUsuarios(event.target.value);
  };

  const handleMouseWheel = e => {
    const container = containerRef.current;
    if (!container) return;

    const currentScroll = container.scrollTop;
    const scrollAmount = 200; // Adjust as needed
    const deltaY = e.deltaY;

    if (deltaY > 0) {
      container.scrollTop = Math.min(
        container.scrollHeight - container.clientHeight,
        currentScroll + scrollAmount
      );
    } else {
      container.scrollTop = Math.max(0, currentScroll - scrollAmount);
    }
  };

  const containerRef = useRef(null);

  return (
    <div id="calculadora-page" onWheel={handleMouseWheel} ref={containerRef}>
      {/* <div style="width: 100%; height: 100%; background: #F112E8; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 32px">
        ¡Probalo gratis durante 3 días!
      </div> */}
      <br></br>

      <div id="paso7" className="container">
        <br></br>
        <center>
          <div className="probalo">
            <span>¡Probalo gratis durante 3 días!</span>
          </div>
        </center>
        <br></br>

        <div className="calculadora text-center justify-text-around">
          <div className="hablemos-titulo d-flex row ">
            En Carnaval App pagás por el alcance real de tus anuncios
          </div>

          <span className="hablemos-texto">
            Se te cobrará $ 300 (UYU) por cada 100 impresiones o clicks
          </span>
          <br />
          <br />
          <br />
          <br />
          <span className="hablemos-texto-2 my-1">
            ESTABLECÉ TU LÍMITE PRESUPUESTAL
          </span>
          <br />
        </div>

        <div className="calculadora mt-4">
          <div className="total">
            <div className=" d-flex row justify-content-center">
              <div className="slider col-auto ml-3">
                <Box sx={{ width: 300 }}>
                  <Slider
                    sx={{
                      "& .MuiSlider-thumb": {
                        color: "#F112E8"
                      },
                      "& .MuiSlider-track": {
                        color: "#F112E8"
                      },
                      "& .MuiSlider-rail": {
                        color: "#FFF"
                      },
                      "& .MuiSlider-active": {
                        color: "#F112E8"
                      }
                    }}
                    aria-label="Always visible"
                    value={usuarios}
                    onChange={handleUsuariosChange}
                    getAriaValueText={valuetext}
                    step={100}
                    min={2000}
                    max={60000}
                    valueLabelDisplay="auto"
                  />
                </Box>
              </div>
              <div className="separador-precio" style={{ width: "60px" }}></div>
              <div className="mt-2 label-precio">
                <h5>
                  $ {usuarios} pesos*{" "}
                  <span style={{ fontSize: "x-small" }}> + IVA</span>{" "}
                </h5>
              </div>
            </div>
          </div>
          <div className="text-center  hablemos-texto mb-4">
            * Equivalente a {calcularCosto()} impresiones o clicks de tu anuncio
          </div>
        </div>

        <div className="col text-center mt-4">
          <a
            href={`https://wa.me/59899032442?text=Hola+Carnaval+App!+Quiero+anunciar+en+la+app+con+límite+UYU+${usuarios}&type=phone_number&app_absent=0`}
          >
            <Button className="enviar-pedido" variant={"contained"}>
              ENVIAR PEDIDO
            </Button>
          </a>
        </div>

        <div className="text-center  hablemos-texto mb-4">
          Te avisaremos cuando estés por alcanzar tu límite de presupuesto
        </div>
        <div style={{ height: "60px" }}></div>
      </div>
    </div>
  );
};

export default CostoPublicidad;
