export const MURGAS = "MURGAS";
export const PARODISTAS = "PARODISTAS";
export const HUMORISTAS = "HUMORISTAS";
export const REVISTAS = "REVISTAS";
export const COMPARSAS = "COMPARSAS";
export const FUERADECONCURSO = "FUERA DE CONCURSO";

export const categorias = [
  { id: 1, nombre: MURGAS },
  { id: 2, nombre: PARODISTAS },
  { id: 3, nombre: HUMORISTAS },
  { id: 4, nombre: REVISTAS },
  { id: 5, nombre: COMPARSAS },
  { id: 6, nombre: FUERADECONCURSO }
];

export const estadosConcurso = {
  primeraRueda: 1,
  segundaRueda: 2,
  liguilla: 3,
  nocheFallos: 4,
  resultadosDisponibles: 5
};

export const estadoConcursoDescripcion = estadoConcursoId => {
  if (estadoConcursoId == estadosConcurso.primeraRueda) return "Primera rueda";
  if (estadoConcursoId == estadosConcurso.segundaRueda) return "Segunda rueda";
  if (estadoConcursoId == estadosConcurso.liguilla) return "Liguilla";
  if (estadoConcursoId == estadosConcurso.nocheFallos) return "Noche de fallos";
  if (estadoConcursoId == estadosConcurso.resultadosDisponibles)
    return "Resultados disponibles";
};
