import React, { useState } from "react";

export const QuantityInput = ({
  minValue,
  maxValue,
  value,
  onChange,
  label,
}) => {
  const increment = () => {
    if (isNaN(maxValue) || (!isNaN(maxValue) && value < maxValue))
      onChange?.(value + 1);
  };

  const decrement = () => {
    if (isNaN(minValue) || (!isNaN(minValue) && value > minValue))
      onChange?.(value - 1);
  };

  return (
    <div className="d-flex row align-items-center">
      {label && <span className="col text-right">{label}</span>}
      <div data-quantity className=" col quantity-input">
        <button className="sub" onClick={decrement}>
          &mdash;
        </button>
        <input type="number" value={value} readonly />
        <button className="add" onClick={increment}>
          &#xff0b;
        </button>
      </div>
    </div>
  );
};

export default QuantityInput;
