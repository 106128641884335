import React, { useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { TextField, Button, Select, MenuItem } from "@mui/material";
import { estadosConcurso } from "../utils/constants";
import { showSuccessMessage } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import { useParams } from "react-router-dom";

export const ConcursoCambiarEstado = () => {
  const { _concursoId, _estadoActual } = useParams();
  const [nombre, setNombre] = useState("");
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const [anio, setAnio] = useState(new Date().getFullYear());
  const sendRequest = useRequestHandler();
  const dispatch = useDispatch();
  const [estado, setEstado] = useState(_estadoActual);

  const handleGuardar = () => {
    sendRequest(
      Servicios.ModificarConcursoEstado,
      [_concursoId, estado],
      () => {
        dispatch(showSuccessMessage("Concurso modificado correctamente"));
      }
    );
  };

  return (
    <>
      <>
        <div className="justify-content-around ">
          <div className="d-flex row m-2 justify-content-between">
            <div className="col">
              <h4>{editando ? "Modificar Concurso" : "Nuevo Concurso"}</h4>
            </div>
          </div>
          <hr></hr>
          <div className="justify-content-center w-auto d-flex row">
            <div className="col ">
              <h5 className="text-center">Cambiar estado del concurso</h5>
              <hr></hr>
              <div className="col m-2">
                <TextField
                  disabled
                  className="w-100"
                  size="small"
                  label="Nombre"
                  value={nombre}
                />
              </div>
              <div className="col m-2">
                <TextField
                  disabled
                  className="w-100"
                  size="small"
                  label="Año"
                  value={anio}
                />
              </div>
              <div className="col m-2">
                <Select
                  size="small"
                  placeholder="Estado"
                  className="w-100"
                  value={estado}
                  onChange={event => setEstado(event.target.value)}
                >
                  <MenuItem value={estadosConcurso.primeraRueda}>
                    Primera rueda
                  </MenuItem>
                  <MenuItem value={estadosConcurso.segundaRueda}>
                    Segunda rueda
                  </MenuItem>
                  <MenuItem value={estadosConcurso.liguilla}>Liguilla</MenuItem>
                  <MenuItem value={estadosConcurso.nocheFallos}>
                    Noche de fallos
                  </MenuItem>
                  <MenuItem value={estadosConcurso.resultadosDisponibles}>
                    Resultados disponibles
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>

          <hr></hr>

          <hr></hr>
          <div className="d-flex row justify-content-center w-auto">
            <div className="col text-left">
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className="col text-right">
              <Button variant={"contained"} onClick={handleGuardar}>
                Guardar
              </Button>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default withAuthenticationRequired(ConcursoCambiarEstado, {
  onRedirecting: () => <Loading />
});
