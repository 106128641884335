import { TextField } from "@mui/material";
import React, { useRef } from "react";

export const PronosticarCategoria = ({
  conjuntos,
  setConjuntos,
  title,
  disabled = false,
  liguilla = 0,
  incluirEliminados = false
}) => {
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    const className =
      position < dragItem.current
        ? "top-line-blue"
        : position > dragItem.current
        ? "bottom-line-blue"
        : "";
    document.querySelectorAll("." + className).forEach(element => {
      element.classList.remove(className);
    });
    e.target.classList.add(className);
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const drop = e => {
    const copyListItems = [...conjuntos];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setConjuntos(
      copyListItems.map((item, i) => {
        return { ...item, posicion: i + 1 };
      })
    );
    document
      .querySelectorAll(".top-line-blue, .bottom-line-blue")
      .forEach(element => {
        element.classList.remove("top-line-blue");
        element.classList.remove("bottom-line-blue");
      });
  };

  const itemConjunto = (item, i) => {
    return (
      <>
        <div
          className={`d-flex row ${disabled ? "" : "item"} ${
            dragItem.current != null
              ? dragItem.current > i
                ? "top-line-blue"
                : "bottom-line-blue"
              : dragItem.current == i
              ? "hidden"
              : "visible"
          } ${((liguilla && i + 1 > liguilla) ||
            (incluirEliminados && !item?.clasificadoLiguilla)) &&
            "opaco"}`}
          draggable={!disabled}
          onDragStart={e => dragStart(e, i)}
          onDragEnter={e => dragEnter(e, i)}
          onDragEnd={drop}
          key={i}
        >
          {!liguilla && (
            <div className={`col-auto text-left m-auto`}>
              <span>{i + 1}</span>
            </div>
          )}
          <div className="col text-center m-auto ">{item?.nombre}</div>
          <TextField
            value={item?.puntosObtenidos}
            onChange={e => {
              setConjuntos(
                conjuntos.map(x => {
                  if (x.id == item.id)
                    return {
                      ...x,
                      puntosObtenidos: e.target.value
                    };
                  return x;
                })
              );
            }}
            size="small"
            className="p-2"
            style={{ width: "100px" }}
          />
        </div>
        {i + 1 == liguilla && <hr className="separador-liguilla"></hr>}
      </>
    );
  };

  return (
    <div className="text-center listado-conjunto-container">
      <h5>{title}</h5>
      <hr></hr>
      <div className="draggable-item">
        {conjuntos?.map((item, i) => {
          return itemConjunto(item, i);
        })}
      </div>
    </div>
  );
};

export default PronosticarCategoria;
