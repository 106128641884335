import React, { useEffect, useRef, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { MenuItem, Select, TextField, Button, Input } from "@mui/material";
import { categorias } from "../utils/constants";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { showErrorMessage } from "../redux/actions";
import { useDispatch } from "react-redux";
import GoogleMapsSearch from "../components/GoogleMapsSearch";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";

export const Conjuntos = () => {
  const [conjuntos, setConjuntos] = useState([]);
  const [categoria, setCategoria] = useState(0);
  const [agregando, setAgregando] = useState(false);
  const [nombre, setNombre] = useState("");
  const [elenco, setElenco] = useState([]);
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const [localEnsayo, setLocalEnsayo] = useState("");
  const [localEnsayoDireccion, setLocalEnsayoDireccion] = useState("");
  const center = { lat: -34.895033, lng: -56.165009 };
  const [marker, setMarker] = useState(center);
  const [imagen, setImagen] = useState();
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [elencoRol, setElencoRol] = useState("");
  const [elencoNombre, setElencoNombre] = useState("");
  const sendRequest = useRequestHandler();

  const refrescarGrilla = () => {
    sendRequest(Servicios.ConjuntosPorCategoria, [categoria], data => {
      setConjuntos(data?.data);
    });
  };
  const dispatch = useDispatch();

  const limpiarForm = () => {
    setNombre("");
    setCategoria(0);
    setElenco([]);
    setMarker(center);
    setImagen({});
    setImgPreviewSrc("");
    setLocalEnsayo("");
    setLocalEnsayoDireccion("");
  };

  useEffect(() => {
    refrescarGrilla();
  }, [categoria]);

  const eliminarConjunto = id => {
    sendRequest(Servicios.EliminarConjunto, [id], refrescarGrilla);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 10 },
    { field: "nombre", headerName: "Nombre", flex: 80 },
    { field: "categoria", headerName: "Categoria", flex: 80 },
    {
      field: "actionEdit",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setIdEditando(params.row.id);
          sendRequest(Servicios.ObtenerConjuntoPorId, [params.row.id], data => {
            setNombre(data.data.nombre);
            setCategoria(data.data.categoriaId);
            setElenco(data.data.elenco);
            setMarker({
              lat: data.data.localEnsayoLatitud,
              lng: data.data.localEnsayoLongitud
            });
            setImgPreviewSrc(data.data.imagenPath);
            setLocalEnsayo(data.data.localEnsayoNombre);
            setLocalEnsayoDireccion(data.data.localEnsayoDireccion);
          });
        };

        return <EditIcon className="action-icon" onClick={onClick} />;
      }
    },
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar el conjunto?")) {
            eliminarConjunto(params?.row?.id);
          }
          refrescarGrilla();
        };

        return <DeleteIcon className="action-icon" onClick={onClick} />;
      }
    }
  ];

  const drop = e => {
    const copyListItems = [...elenco];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setElenco(
      copyListItems.map((item, i) => {
        return { ...item, posicion: i + 1 };
      })
    );
    document
      .querySelectorAll(".top-line-blue, .bottom-line-blue")
      .forEach(element => {
        element.classList.remove("top-line-blue");
        element.classList.remove("bottom-line-blue");
      });
  };

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        Servicios.ModificarConjunto,
        [
          {
            id: idEditando,
            nombre: nombre,
            categoriaId: categoria,
            elenco: elenco,
            localEnsayo: {
              nombre: localEnsayo,
              direccion: localEnsayoDireccion,
              longitud: marker.lng,
              latitud: marker.lat
            }
          },
          imagen
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    } else {
      sendRequest(
        Servicios.CrearConjunto,
        [
          {
            id: idEditando,
            nombre: nombre,
            categoriaId: categoria,
            elenco: elenco,
            localEnsayo: {
              nombre: localEnsayo,
              direccion: localEnsayoDireccion,
              longitud: marker.lng,
              latitud: marker.lat
            }
          },
          imagen
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
        }
      );
    }
  };

  const handleChange = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      setImagen({});
      return false;
    }
    setImagen(file);
    var reader = new FileReader();
    reader.onload = function(e) {
      setImgPreviewSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    const className =
      position < dragItem.current
        ? "top-line-blue"
        : position > dragItem.current
        ? "bottom-line-blue"
        : "";
    document.querySelectorAll("." + className).forEach(element => {
      element.classList.remove(className);
    });
    e.target.classList.add(className);
  };

  return (
    <>
      <>
        {!agregando ? (
          <>
            <div className="d-flex row m-2 justify-content-between">
              <div className="col-4">
                <h4>Listado de conjuntos</h4>
              </div>
              <div className="col">
                <Select
                  style={{ width: 200 }}
                  size="small"
                  placeholder="Categoría"
                  value={categoria}
                  onChange={e => setCategoria(e.target.value)}
                >
                  <MenuItem key={0} value={0}>
                    {"Categoría..."}
                  </MenuItem>
                  {categorias?.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    setCategoria(0);
                    setAgregando(true);
                  }}
                >
                  Agregar
                </Button>
              </div>
            </div>
            <hr></hr>
            <div
              className="row m-2 justify-content-center"
              style={{ width: "100%" }}
            >
              {conjuntos?.length > 0 ? (
                <DataGrid
                  rows={conjuntos}
                  columns={columns}
                  paginationModel={{ page: 0, pageSize: 50 }}
                  checkboxSelection={false}
                />
              ) : (
                <h5 className="">No se encontraron resultados</h5>
              )}
            </div>
          </>
        ) : (
          <div className="justify-content-around ">
            <div className="justify-content-center w-auto d-flex row">
              <div className="col">
                <div className="d-flex row justify-content-between">
                  <div className="col m-2">
                    <h4>
                      {editando ? "Modificar conjunto" : "Nuevo conjunto"}
                    </h4>
                  </div>
                </div>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Nombre"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                  />
                </div>

                <div className="col m-2">
                  <Select
                    className="w-100"
                    size="small"
                    placeholder="Categoría"
                    value={categoria}
                    onChange={e => setCategoria(e.target.value)}
                  >
                    <MenuItem key={0} value={0}>
                      {"Categoría"}
                    </MenuItem>
                    {categorias?.map(c => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="m-2">
                  <h6>Elenco</h6>
                  <div className="col m-2 draggable-item">
                    {elenco?.map((x, i) => (
                      <div
                        draggable
                        onDragStart={e => dragStart(e, i)}
                        onDragEnter={e => dragEnter(e, i)}
                        onDragEnd={drop}
                        className={`row m-2 item  ${
                          dragItem.current != null
                            ? dragItem.current > i
                              ? "top-line-blue"
                              : "bottom-line-blue"
                            : dragItem.current == i
                            ? "hidden"
                            : "visible"
                        } `}
                        key={i}
                      >
                        <b className="col-auto tex-left">{x.clave}</b>
                        <span className="col text-left ml-2">
                          {x.descripcion}
                        </span>
                        <div className="col-auto">
                          <DeleteIcon
                            className="action-icon"
                            onClick={() => {
                              setElenco(elenco.filter(y => y.clave != x.clave));
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col m-2">
                    <TextField
                      className="col m-2"
                      size="small"
                      label="Rol"
                      value={elencoRol}
                      onChange={e => setElencoRol(e.target.value)}
                    ></TextField>
                    <TextField
                      className="col m-2"
                      size="small"
                      label="Nombre/s"
                      value={elencoNombre}
                      onChange={e => setElencoNombre(e.target.value)}
                      multiline
                      rows={4}
                    ></TextField>
                    <div className="text-right">
                      <Button
                        variant={"outlined"}
                        onClick={() => {
                          setElenco([
                            ...elenco,
                            { clave: elencoRol, descripcion: elencoNombre }
                          ]);
                          setElencoNombre("");
                          setElencoRol("");
                        }}
                      >
                        + Agregar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="col file-upload ml-4">
                  <div className="row px-4">
                    <label
                      className="col d-flex justify-content-between"
                      htmlFor="upload-photo"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={handleChange}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        component="span"
                      >
                        Cargar imagen
                      </Button>
                      <span className="m-2">{imagen?.name}</span>
                    </label>
                  </div>
                  {imagen && (
                    <div id="imagePreview" className="col text-center">
                      <img src={imgPreviewSrc} className="img-preview"></img>
                    </div>
                  )}
                </div>
                <div id="map" className="col w-100 m-2">
                  <GoogleMapsSearch
                    title="Local de ensayo"
                    texto={localEnsayo}
                    setTexto={setLocalEnsayo}
                    marker={marker}
                    setMarker={setMarker}
                  ></GoogleMapsSearch>
                </div>
                <div className="col w-100 m-2">
                  <TextField
                    className="col m-2"
                    size="small"
                    label="Dirección"
                    value={localEnsayoDireccion}
                    onChange={e => setLocalEnsayoDireccion(e.target.value)}
                  ></TextField>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    limpiarForm();
                    setEditando(false);
                    setAgregando(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default withAuthenticationRequired(Conjuntos, {
  onRedirecting: () => <Loading />
});
