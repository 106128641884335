import ConjuntoService from "./ConjuntoService";
import TabladoService from "./TabladoService";
import PencaService from "./PencaService";
import ConcursoService from "./ConcursoService";
import UsuarioService from "./UsuarioService";
import BannerService from "./BannerService";

const Servicios = {
  ...ConjuntoService,
  ...TabladoService,
  ...PencaService,
  ...ConcursoService,
  ...UsuarioService,
  ...BannerService
};
export default Servicios;
