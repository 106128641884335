import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import { useDispatch } from "react-redux";
import { showErrorMessage } from "../redux/actions";
import { Switch } from "@mui/material";

const Notificaciones = () => {
  const sendRequest = useRequestHandler();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imagen, setImagen] = useState(null);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [link, setLink] = useState("");
  const dispatch = useDispatch();
  const [modoPrueba, setModoPrueba] = useState(true);

  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  const handleContentChange = event => {
    setContent(event.target.value);
  };

  const [title2, setTitle2] = useState("");
  const [content2, setContent2] = useState("");

  const handleTitleChange2 = event => {
    setTitle2(event.target.value);
  };

  const handleContentChange2 = event => {
    setContent2(event.target.value);
  };

  const handleSubmit = () => {
    sendRequest(Servicios.EnviarNotificaciones, [
      title,
      content,
      title2,
      content2,
      link,
      imagen,
      modoPrueba
    ]);
    setTitle("");
    setContent("");
    setTitle2("");
    setContent2("");
    setImagen();
    setImgPreviewSrc("");
    setLink("");
  };

  const handleChange = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      setImagen(null);
      return false;
    }
    setImagen(file);
    var reader = new FileReader();
    reader.onload = function(e) {
      setImgPreviewSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="d-flex row">
        <div className="col">
          <h5>Notificaciones push</h5>
          <div className="m-2">
            <TextField
              size="small"
              label="Título"
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="m-2">
            <TextField
              size="small"
              label="Contenido"
              value={content}
              onChange={handleContentChange}
            />
          </div>
          <br></br>
          <h5>Diálogo en app abierta</h5>
          <div className="m-2">
            <TextField
              size="small"
              label="Título"
              value={title2}
              onChange={handleTitleChange2}
            />
          </div>
          <div className="m-2">
            <TextField
              size="small"
              label="Contenido"
              value={content2}
              onChange={handleContentChange2}
            />
          </div>
          <div className="d-flex row">
            <div className="col-auto">Modo prueba</div>
            <div className="col">
              <Switch
                checked={modoPrueba}
                onChange={e => setModoPrueba(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
        </div>
        <div className="col file-upload ml-4">
          <div className="row px-4">
            <label
              className="col d-flex justify-content-between"
              htmlFor="upload-photo"
            >
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={handleChange}
              />
              <Button color="secondary" variant="contained" component="span">
                Cargar imagen
              </Button>
              <span className="m-2">{imagen?.name}</span>
            </label>
          </div>

          {imgPreviewSrc && (
            <div id="imagePreview" className="col text-center">
              <img src={imgPreviewSrc} className="img-preview"></img>
            </div>
          )}
        </div>
      </div>
      <div className="row m-2">
        <TextField
          className="w-100"
          size="small"
          label="Link"
          value={link}
          onChange={e => setLink(e.target.value)}
        />
      </div>
      <Button variant="contained" onClick={handleSubmit}>
        Enviar
      </Button>
    </>
  );
};

export default Notificaciones;
