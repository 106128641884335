import { BackendApi } from "./HttpBaseService";

const ConjuntosPorCategoria = async categoria => {
  return await BackendApi.get(
    `Conjunto/ListarPorCategoria?categoria=${categoria}`
  );
};
const CrearConjunto = async (conjunto, imagen) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  const json = JSON.stringify(conjunto);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("conjunto", blob);
  return BackendApi.post(`Conjunto/Crear`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const EliminarConjunto = async id => {
  return await BackendApi.delete(`Conjunto/Eliminar?id=${id}`);
};

const ModificarConjunto = async (conjunto, imagen) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  const json = JSON.stringify(conjunto);
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("conjunto", blob);
  return BackendApi.put(`Conjunto/Modificar`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const ConjuntosConcursan = async (textoBusqueda = "", concursoId = 0) => {
  return await BackendApi.get(
    `Conjunto/Concursan?textoBusqueda=${textoBusqueda}&concursoId=${concursoId}`
  );
};

const ObtenerConjuntoPorId = async id => {
  return await BackendApi.get(`Conjunto/${id}`);
};

const ConjuntoService = {
  ConjuntosPorCategoria,
  CrearConjunto,
  EliminarConjunto,
  ModificarConjunto,
  ConjuntosConcursan,
  ObtenerConjuntoPorId
};

export default ConjuntoService;
