import React, { useEffect } from "react";
import initFontAwesome from "./utils/initFontAwesome";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import PageRoutes from "./pageRoutes";
import SnackbarWrapper from "./components/SnackbarWrapper";

initFontAwesome();

const App = () => {
  
  return (
    <>
      <Provider store={store}>
        <SnackbarWrapper />
        <PageRoutes />
      </Provider>
    </>
  );
};

export default App;
