import React from "react";
import "./Calculadora.scss";

const PoliticaPrivacidad = () => {
  return (
    <div id="calculadora-page" className="w-100 ">
      <div className="container py-4">
        <h1>Carnaval App - Política de privacidad</h1>
        <hr></hr>

        <p>
          <strong>Última actualización:</strong> [18/11/2023]
        </p>

        <h4>1. Información Personal Recopilada</h4>
        <p>
          Carnaval App recopila el nombre, la foto y la dirección de correo
          electrónico o número de teléfono proporcionados por la plataforma de
          inicio de sesión seleccionada o directamente por el usuario.
        </p>

        <h4>2. Almacenamiento de Información</h4>
        <p>
          La información recopilada se almacena de forma segura en la base de
          datos de Carnaval App.
        </p>

        <h4>3. Propósito de la Recopilación</h4>
        <p>
          La información se utiliza para permitir a los usuarios interactuar
          entre sí, encontrar amigos dentro de la aplicación y mejorar la
          experiencia social.
        </p>

        <h4>4. Compartir con Terceros (Anunciantes)</h4>
        <p>
          Los anunciantes pueden acceder al listado de nombres de usuarios,
          correos electrónicos y/o números de teléfono con fines publicitarios.
        </p>

        <h4>5. Privacidad de la Información del Usuario</h4>
        <p>
          La foto y el nombre elegidos por el usuario son públicos dentro de la
          aplicación. Otros datos del usuario son privados y solo visibles y
          modificables por el propio usuario.
        </p>

        <h4>6. Almacenamiento Local</h4>
        <p>
          Carnaval App utiliza el almacenamiento local del dispositivo móvil
          para mejorar la eficiencia y la experiencia del usuario.
        </p>

        <h4>7. Solicitud de Información</h4>
        <p>
          Los usuarios pueden solicitar información adicional o realizar
          consultas sobre la privacidad enviando un correo electrónico a
          hola@carnavalapp.uy.
        </p>

        <h4>8. Notificación de Cambios en la Política de Privacidad</h4>
        <p>
          Cualquier cambio en la política de privacidad se notificará a los
          usuarios a través de una notificación dentro de la aplicación.
        </p>

        <h4>9. Privacidad de los Usuarios</h4>
        <p>
          La privacidad de los usuarios se gestiona de la misma manera que el
          resto de los usuarios, garantizando la seguridad y confidencialidad de
          la información.
        </p>

        <h4>10. Privacidad de los Pronósticos de Pencas</h4>
        <p>
          Los pronósticos de las parrillas son privados y secretos hasta que se
          conocen los resultados del concurso. Después de los resultados, cada
          usuario puede ver los pronósticos de los demás competidores de la
          misma penca.
        </p>

        <h4>11. Seguimiento y Publicidad Personalizada</h4>
        <p>
          Carnaval App no realiza seguimiento y no utiliza información personal
          para ofrecer publicidad personalizada.
        </p>
      </div>
    </div>
  );
};

export default PoliticaPrivacidad;
