import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

export default function () {
  const { open: showBackdrop, message } = useSelector(
    (store) => store.backdrop
  );
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 100000 }}
      open={showBackdrop ?? false}
    >
      <div className="text-center">
        <div>
          <CircularProgress color="inherit" />
        </div>
        {message && <div className="text-white mt-3">{message}</div>}
      </div>
    </Backdrop>
  );
}
