import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { TextField, Button, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { BiCalendarAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { showErrorMessage, showSuccessMessage } from "../redux/actions";
import { useDispatch } from "react-redux";
import GoogleMapsSearch from "../components/GoogleMapsSearch";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";

export const Tablados = () => {
  const [tablados, setTablados] = useState([]);
  const [agregando, setAgregando] = useState(false);
  const [nombre, setNombre] = useState("");
  const [refreshGrilla, setRefreshGrilla] = useState(0);
  const [editando, setEditando] = useState(false);
  const [idEditando, setIdEditando] = useState(0);
  const center = { lat: -34.895033, lng: -56.165009 };
  const [marker, setMarker] = useState(center);
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [link, setLink] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sendRequest = useRequestHandler();
  const [imagen, setImagen] = useState(null);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [incluirInactivos, setIncluirInactivos] = useState(false);

  const refrescarGrilla = () => {
    setRefreshGrilla(refreshGrilla + 1);
  };

  useEffect(() => {
    sendRequest(Servicios.ObtenerTablados, [incluirInactivos], data => {
      setTablados(data?.data);
    });
  }, [refreshGrilla, incluirInactivos]);

  const eliminarTablado = id => {
    sendRequest(Servicios.EliminarTablado, [id], data => {
      if (data?.data) {
        dispatch(showSuccessMessage("Tablado eliminado correctamente"));
        refrescarGrilla();
      }
    });
  };

  const toggleActivacionTablado = (id, event) => {
    sendRequest(
      Servicios.CambiarActivacionTablado,
      [id, event.target.checked],
      () => {
        refrescarGrilla();
      },
      () => event.stopPropagation()
    );
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 10 },
    { field: "nombre", headerName: "Nombre", flex: 80 },
    {
      field: "actionSwitch",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        return (
          <Switch
            checked={params.row.activo}
            onChange={e => toggleActivacionTablado(params.row.id, e)}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      }
    },
    {
      field: "actionProgramacion",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          navigate(`/backoffice/programacion/${params?.row?.id}`);
        };

        return (
          <BiCalendarAlt
            size={28}
            className="action-icon"
            title="PROGRAMACIÓN"
            onClick={onClick}
          />
        );
      }
    },
    {
      field: "actionEdit",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          setAgregando(true);
          setEditando(true);
          setNombre(params.row.nombre);
          setTelefono(params.row.telefono);
          setDireccion(params.row.direccion);
          setDescripcion(params.row.descripcion);
          setLink(params.row.link);
          setMarker({
            lat: params.row.latitud,
            lng: params.row.longitud
          });
          setIdEditando(params.row.id);
          setImagen(null);
          setImgPreviewSrc(params.row.imagenPath);
        };

        return <EditIcon className="action-icon" onClick={onClick} />;
      }
    },
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: params => {
        const onClick = e => {
          if (window.confirm("¿Confirmás eliminar el Tablado?")) {
            eliminarTablado(params?.row?.id);
          }
          refrescarGrilla();
        };

        return <DeleteIcon className="action-icon" onClick={onClick} />;
      }
    }
  ];

  const limpiarForm = () => {
    setNombre("");
    setTelefono("");
    setDireccion("");
    setDescripcion("");
    setLink("");
    setMarker(center);
    setImagen(null);
    setImgPreviewSrc("");
  };

  const handleGuardar = () => {
    if (editando) {
      sendRequest(
        Servicios.ModificarTablado,
        [
          {
            id: idEditando,
            nombre: nombre,
            direccion: direccion,
            telefono: telefono,
            descripcion: descripcion,
            longitud: marker.lng,
            latitud: marker.lat,
            link: link
          },
          imagen
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
          dispatch(showSuccessMessage("Tablado modificado correctamente"));
        }
      );
    } else {
      sendRequest(
        Servicios.CrearTablado,
        [
          {
            nombre: nombre,
            direccion: direccion,
            telefono: telefono,
            descripcion: descripcion,
            longitud: marker.lng,
            latitud: marker.lat,
            link: link
          },
          imagen
        ],
        () => {
          limpiarForm();
          setEditando(false);
          setAgregando(false);
          refrescarGrilla();
          dispatch(showSuccessMessage("Tablado creado correctamente"));
        }
      );
    }
  };

  const handleChange = event => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(file.name)) {
      dispatch(showErrorMessage("Tipo de archivo no válido"));
      setImagen(null);
      return false;
    }
    setImagen(file);
    var reader = new FileReader();
    reader.onload = function(e) {
      setImgPreviewSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <>
        {!agregando ? (
          <>
            <div className="d-flex row m-2 justify-content-between">
              <div className="col-4">
                <h4>Listado de tablados</h4>
              </div>
              <div className="col-auto m-2">
                <Switch
                  checked={incluirInactivos}
                  onChange={e => setIncluirInactivos(!incluirInactivos)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                Incluir inactivos
              </div>
              <div>
                <Button
                  variant={"contained"}
                  onClick={() => setAgregando(true)}
                >
                  Agregar
                </Button>
              </div>
            </div>
            <hr></hr>
            <div
              className="row m-2 justify-content-center"
              style={{ width: "100%" }}
            >
              {tablados?.length > 0 ? (
                <DataGrid
                  rows={tablados}
                  columns={columns}
                  checkboxSelection={false}
                  disableRowSelectionOnClick
                  paginationMode="server"
                />
              ) : (
                <h5 className="">No se encontraron resultados</h5>
              )}
            </div>
          </>
        ) : (
          <div className="justify-content-around ">
            <div className="d-flex row m-2 justify-content-between">
              <div className="col">
                <h4>{editando ? "Modificar Tablado" : "Nuevo Tablado"}</h4>
              </div>
            </div>
            <hr></hr>
            <div className="justify-content-center w-auto d-flex row">
              <div className="col">
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Nombre"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                  />
                </div>
                <div className="col m-2">
                  <TextField
                    className="w-100"
                    size="small"
                    label="Teléfono"
                    value={telefono}
                    onChange={e => setTelefono(e.target.value)}
                  />
                </div>
                <div id="map" className="col w-100 m-2">
                  <GoogleMapsSearch
                    title="Ubicación"
                    texto={direccion}
                    setTexto={setDireccion}
                    marker={marker}
                    setMarker={setMarker}
                  ></GoogleMapsSearch>
                </div>
              </div>

              <div className="col m-2">
                <div className="col file-upload ml-4">
                  <div className="row px-4">
                    <label
                      className="col d-flex justify-content-between"
                      htmlFor="upload-photo"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={handleChange}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        component="span"
                      >
                        Cargar imagen
                      </Button>
                      <span className="m-2">{imagen?.name}</span>
                    </label>
                  </div>
                  {imgPreviewSrc && (
                    <div id="imagePreview" className="col text-center">
                      <img src={imgPreviewSrc} className="img-preview"></img>
                    </div>
                  )}
                </div>
                <TextField
                  className="w-100"
                  size="small"
                  label="Descripcion"
                  value={descripcion}
                  onChange={e => setDescripcion(e.target.value)}
                  multiline
                  rows={18}
                />
              </div>
            </div>
            <div className="d-flex row m-1">
              <div className="col m-2">
                <TextField
                  className="w-100"
                  size="small"
                  label="Link"
                  value={link}
                  onChange={e => setLink(e.target.value)}
                />
              </div>
            </div>
            <hr></hr>
            <div className="d-flex row justify-content-center w-auto">
              <div className="col text-left">
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    limpiarForm();
                    setEditando(false);
                    setAgregando(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col text-right">
                <Button variant={"contained"} onClick={handleGuardar}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default withAuthenticationRequired(Tablados, {
  onRedirecting: () => <Loading />
});
