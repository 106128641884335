import React, { useEffect, useRef, useState } from "react";
import { Box, MenuItem, Select, Slider } from "@mui/material";
import "./Calculadora.scss";
import { useRequestHandler } from "../utils/useRequestHandler";
import Servicios from "../services";
import { useDispatch } from "react-redux";
import { formatearFechaDDMMYYHHMM } from "../utils/dates";
import { IoReload } from "react-icons/io5";
import Loading from "../components/Loading";
import { Button, Spinner } from "reactstrap";

const Dashboard = () => {
  const sendRequest = useRequestHandler();
  const [datosDashboard, setDatosDashboard] = useState({});
  const [loading, setLoading] = useState(false);
  const obtenerDatos = () => {
    setLoading(true);
    sendRequest(Servicios.ObtenerDatosDashboard, [], data => {
      setLoading(false);
      setDatosDashboard(data?.data);
    });
  };

  useEffect(() => {
    obtenerDatos();
  }, []);

  const handleMouseWheel = e => {
    const container = containerRef.current;
    if (!container) return;

    const currentScroll = container.scrollTop;
    const scrollAmount = 200; // Adjust as needed
    const deltaY = e.deltaY;

    if (deltaY > 0) {
      container.scrollTop = Math.min(
        container.scrollHeight - container.clientHeight,
        currentScroll + scrollAmount
      );
    } else {
      container.scrollTop = Math.max(0, currentScroll - scrollAmount);
    }
  };

  const containerRef = useRef(null);

  return (
    <div id="calculadora-page" onWheel={handleMouseWheel} ref={containerRef}>
      <br></br>
      <div className="logo-carnavalapp" alt="Carnaval App"></div>
      <div
        className="logo-carnavalapp-eslogan"
        alt="La app del Carnaval Uruguayo"
      ></div>
      <div id="paso7" className="container">
        {datosDashboard && (
          <div className="calculadora text-center justify-text-around">
            <div className="hablemos-titulo d-flex row ">
              Métricas de la app
            </div>
            <span>
              Última actualización:{" "}
              {formatearFechaDDMMYYHHMM(datosDashboard?.fechaHora)}
            </span>
            <IoReload className={"mx-1"} onClick={() => obtenerDatos()} />
            <br></br>
            {loading ? (
              <div className="m-4">
                <Spinner />
              </div>
            ) : (
              <>
                <h5 className="mt-4">
                  Usuarios:{" "}
                  <span className="verde">{datosDashboard?.descargas}</span>
                </h5>

                <h5>
                  Registrados:{" "}
                  <span className="verde">{datosDashboard?.registrados}</span>
                </h5>

                {/* <h5>
                  Usuarios activos:{" "}
                  <span className="verde">{datosDashboard?.activos}</span>
                </h5> */}
              </>
            )}
            <hr></hr>
            <div className="hablemos">
              <div className="icono-mascara d-flex row"></div>
              <div className="hablemos-titulo d-flex row">¡Hablemos!</div>
              <div className="hablemos-texto d-flex row">
                Si estás interesado en explorar oportunidades publicitarias en
                Carnaval App o solicitar más información, estamos aquí para
                ayudarte.
              </div>
              <div className=" d-flex row">
                <div className="col text-center mt-4">
                  <a href={`https://carnavalapp.uy/calculadora`}>
                    <Button className="enviar-pedido" variant={"contained"}>
                      SABER MÁS
                    </Button>
                  </a>
                </div>
              </div>
              <a
                href="mailto:hola@carnavalapp.uy"
                className="col row hablemos-mail"
              >
                <div className="col-auto mail-icon"></div>
                <div className="col-auto">hola@carnavalapp.uy</div>
              </a>
              <a
                href="https://wa.me/59899032442?text=Hola+Carnaval+App&type=phone_number&app_absent=0"
                className="col row hablemos-mail"
              >
                <div className="col-auto phone-icon"></div>
                <div className="col-auto">099 032 442</div>
              </a>
            </div>
          </div>
        )}{" "}
      </div>
    </div>
  );
};

export default Dashboard;
