const initialState = {
  snackbarMsg: { tipo: "success", mensaje: "" },
  backdrop: { open: false, message: "" }
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_ERROR_MESSAGE":
      return {
        ...state,
        snackbarMsg: { tipo: "error", mensaje: action.payload }
      };
    case "SHOW_SUCCESS_MESSAGE":
      return {
        ...state,
        snackbarMsg: { tipo: "success", mensaje: action.payload }
      };
    case "SHOW_INFO_MESSAGE":
      return {
        ...state,
        snackbarMsg: { tipo: "info", mensaje: action.payload }
      };
    case "SET_BACKDROP":
      if (
        action.payload.open ||
        (!action.payload.open && action.payload.id == state.backdrop.id)
      )
        return {
          ...state,
          backdrop: {
            open: action.payload.open,
            id: action.payload.id
          }
        };
    default:
      return state;
  }
};
