import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
export const ConjuntoConcursoListItem = ({
  conjuntosCategoria,
  conjuntos,
  setConjuntos,
  setterCategoria,
}) => {
  return (
    <>
      {conjuntosCategoria?.map((x) => {
        return (
          <div className="d-flex row item">
            <div className="col text-left">
              <span>{x?.nombre}</span>
            </div>
            <div className="col-auto">
              <DeleteIcon
                className="action-icon"
                onClick={() => {
                  setterCategoria(
                    conjuntosCategoria.filter((y) => y.id != x.id)
                  );
                  setConjuntos(
                    conjuntos.map((z) => {
                      if (z.id == x.id) {
                        return { ...z, agregado: false };
                      } else {
                        return z;
                      }
                    })
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};
