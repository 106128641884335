import React, { useEffect, useRef, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestHandler } from "../utils/useRequestHandler";

import Servicios from "../services";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEye } from "react-icons/ai";

export const ResultadosPenca = () => {
  const { _pencaId } = useParams();
  const [pencaNombre, setPencaNombre] = useState("");
  const [resultados, setResultados] = useState([]);
  const sendRequest = useRequestHandler();
  const navigate = useNavigate();
  useEffect(() => {
    sendRequest(Servicios.ObtenerPencaPorId, [_pencaId], (data) => {
      setPencaNombre(data?.data?.nombre);
    });
    sendRequest(Servicios.ObtenerResultados, [_pencaId], (data) => {
      setResultados(data?.data);
    });
  }, [_pencaId]);

  const columns = [
    { field: "posicion", headerName: "Posicion", flex: 10 },
    { field: "nombre", headerName: "Nombre", flex: 80 },
    { field: "puntaje", headerName: "Puntaje", flex: 10 },
    {
      field: "actionPredict",
      headerName: "",
      sortable: false,
      minWidth: 10,
      align: "right",
      renderCell: (params) => {
        const onClick = (e) => {
          navigate(
            `/backoffice/pronosticar/${_pencaId}/${params?.row?.usuarioId}`
          );
        };

        return (
          <AiFillEye
            size={28}
            className="action-icon"
            title="Pronosticar"
            onClick={onClick}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="justify-content-around ">
        <div className="d-flex row m-2 justify-content-between">
          <div className="col">
            <h4>{`Resultados finales de penca ${pencaNombre}`}</h4>
            <hr></hr>
            <DataGrid
              rows={resultados}
              columns={columns}
              paginationModel={{ page: 0, pageSize: 50 }}
              checkboxSelection={false}
              getRowId={(row) => row.usuarioId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(ResultadosPenca, {
  onRedirecting: () => <Loading />,
});
