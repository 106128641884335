import moment from "moment";

export const formatearFechaDDMMYYYY = fecha => {
  return moment(fecha).format("DD/MM/YYYY");
};

export const formatearFechaDDMMYY = fecha => {
  return moment(fecha).format("DD/MM/YY");
};

export const formatearFechaDDMMYYHHMM = fecha => {
  return moment(fecha).format("DD/MM/YY HH:mm");
};

export const formatearFechayyyyMMdd = fecha => {
  return moment(fecha).format("yyyy-MM-DD");
};

export const formatearFechaMMYY = fecha => {
  return moment(fecha).format("MM/YY");
};

export const toDateFechaYYYYmmDD = fecha => {
  return moment(moment(fecha).format("yyyy-MM-DD")).toDate();
};

export const formatearHoraHHMM = fecha => {
  return moment(fecha).format("HH:mm");
};

export const formatearHoraHHMMSS = fecha => {
  return moment(fecha).format("HH:mm:ss");
};

export const formatearFechaHoraYYYYMMDDHHMMSS = fecha => {
  return moment(fecha).format("yyyy-MM-DDTHH:mm:ss");
};

const DATE_UNITS = {
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1
};

const getUnitAndValueDate = secondsElapsed => {
  for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
    if (secondsElapsed >= secondsInUnit || unit === "second") {
      const value = Math.floor(secondsElapsed / secondsInUnit) * -1;
      return { value, unit };
    }
  }
};

const rtf = new Intl.RelativeTimeFormat("es-ES", {
  localeMatcher: "best fit",
  numeric: "auto",
  style: "long"
});

const getSecondsDiff = timestamp => {
  return (Date.now() - timestamp) / 1000;
};

export const getTimeAgo = timestamp => {
  const secondsElapsed = getSecondsDiff(timestamp);
  const { value, unit } = getUnitAndValueDate(secondsElapsed);
  return rtf.format(value, unit);
};

export const formatDate = dateParam => {
  let date = new Date(dateParam);
  const daysOfWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado"
  ];
  return `${daysOfWeek[date.getDay()]} ${date.getDate()}-${date.getMonth() +
    1}-${date.getFullYear()}`;
};

export const createCustomDate = (currentDate, hourMinute) => {
  const [hour, minute] = hourMinute.split(":").map(Number);
  if (
    !isNaN(hour) &&
    !isNaN(minute) &&
    hour >= 0 &&
    hour <= 23 &&
    minute >= 0 &&
    minute <= 59
  ) {
    currentDate.setHours(hour, minute, 0, 0);
    return formatearFechaHoraYYYYMMDDHHMMSS(currentDate);
  } else {
  }
};

export const sumarUnDia = fecha => {
  let fechaTmp = new Date(fecha);
  fechaTmp.setDate(fecha.getDate() + 1);
  return fechaTmp;
};

export const restarUnDia = fecha => {
  let fechaTmp = new Date(fecha);
  fechaTmp.setDate(fecha.getDate() - 1);
  return fechaTmp;
};
