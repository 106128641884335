import { BackendApi } from "./HttpBaseService";

const ObtenerUsuarioPorId = async id => {
  return await BackendApi.get(`Usuario/${id}`);
};

const EnviarNotificaciones = async (
  title,
  content,
  title2,
  content2,
  link,
  imagen,
  modoPrueba
) => {
  const formData = new FormData();
  formData.append("imagen", imagen);
  const json = JSON.stringify({
    titulo: title,
    contenido: content,
    tituloOnApp: title2,
    contenidoOnApp: content2,
    link: link,
    modoPrueba
  });
  const blob = new Blob([json], {
    type: "application/json"
  });

  formData.append("notificacion", blob);
  return await BackendApi.post(`Usuario/EnviarNotificaciones`, formData, {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const UsuarioService = {
  ObtenerUsuarioPorId,
  EnviarNotificaciones
};

export default UsuarioService;
