import axios from "axios";

const GetBackendApi = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
      "Content-Type": "application/json"
    }
  };
  let instance = axios.create(defaultOptions);

  return instance;
};

export const BackendApi = GetBackendApi();

export const AutorizarUsuario = async () => {
  return await BackendApi.get(`Login/LoginBackoffice`);
};

export const EnviarPing = async () => {
  return await BackendApi.get(`Login/ping`);
};
