import React, { useEffect, useRef, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestHandler } from "../utils/useRequestHandler";
import { useDispatch } from "react-redux";
import { Button, Switch } from "@mui/material";

import PronosticarCategoria from "./PronosticarCategoria";
import { showSuccessMessage } from "../redux/actions";
import Servicios from "../services";
import {
  COMPARSAS,
  HUMORISTAS,
  MURGAS,
  PARODISTAS,
  REVISTAS
} from "../utils/constants";
import { FaFlagCheckered } from "react-icons/fa";

export const Liguilla = () => {
  const { _concursoId } = useParams();
  const [concurso, setConcurso] = useState({});
  const [murgas, setMurgas] = useState([]);
  const [parodistas, setParodistas] = useState([]);
  const [humoristas, setHumoristas] = useState([]);
  const [revistas, setRevistas] = useState([]);
  const [comparsas, setComparsas] = useState([]);
  const sendRequest = useRequestHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGuardar = () => {
    sendRequest(
      Servicios.GuardarClasificadosLiguilla,
      [_concursoId, { murgas, parodistas, humoristas, revistas, comparsas }],
      () => dispatch(showSuccessMessage("Concurso actualizado correctamente"))
    );
  };

  const compareEnLiguilla = (a, b) => {
    return a.clasificadoLiguilla > b.clasificadoLiguilla ? -1 : 1;
  };

  useEffect(() => {
    sendRequest(Servicios.ObtenerConcursoPorId, [_concursoId], data => {
      setConcurso(data?.data);

      setMurgas(
        ordenarArray(
          data?.data?.conjuntos?.filter(x => x.categoria == MURGAS),
          data?.data?.cantidadMurgasLiguilla
        )
      );
      setParodistas(
        ordenarArray(
          data?.data?.conjuntos?.filter(x => x.categoria == PARODISTAS),
          data?.data?.cantidadParodistasLiguilla
        )
      );
      setHumoristas(
        ordenarArray(
          data?.data?.conjuntos?.filter(x => x.categoria == HUMORISTAS),
          data?.data?.cantidadHumoristasLiguilla
        )
      );
      setRevistas(
        ordenarArray(
          data?.data?.conjuntos?.filter(x => x.categoria == REVISTAS),
          data?.data?.cantidadRevistasLiguilla
        )
      );
      setComparsas(
        ordenarArray(
          data?.data?.conjuntos?.filter(x => x.categoria == COMPARSAS),
          data?.data?.cantidadComparsasLiguilla
        )
      );
    });
  }, [_concursoId]);

  const asignarClasificacionPorPosicion = (arrTmp, cantidad) => {
    arrTmp = arrTmp.map((x, i) => {
      return { ...x, clasificadoLiguilla: i + 1 <= cantidad };
    });
    return arrTmp;
  };

  const ordenarArray = (arrTmp, cantidad) => {
    arrTmp.sort(compareEnLiguilla);
    return asignarClasificacionPorPosicion(arrTmp, cantidad);
  };

  return (
    <>
      <div className="justify-content-around ">
        <div className="d-flex row m-2 justify-content-between">
          <div className="col">
            <h4>{`Clasificados a liguilla ${concurso?.nombre}`}</h4>
          </div>
        </div>

        <hr></hr>
        <div className={"d-flex row justify-content-around pronostico"}>
          <div className="col ">
            <PronosticarCategoria
              title="Murgas"
              conjuntos={murgas}
              setConjuntos={value =>
                setMurgas(
                  asignarClasificacionPorPosicion(
                    value,
                    concurso?.cantidadMurgasLiguilla
                  )
                )
              }
              liguilla={concurso?.cantidadMurgasLiguilla}
            />
            <PronosticarCategoria
              title="Parodistas"
              conjuntos={parodistas}
              setConjuntos={value =>
                setParodistas(
                  asignarClasificacionPorPosicion(
                    value,
                    concurso?.cantidadParodistasLiguilla
                  )
                )
              }
              liguilla={concurso?.cantidadParodistasLiguilla}
            />
          </div>
          <div className="col">
            <PronosticarCategoria
              title="Humoristas"
              conjuntos={humoristas}
              setConjuntos={value =>
                setHumoristas(
                  asignarClasificacionPorPosicion(
                    value,
                    concurso?.cantidadHumoristasLiguilla
                  )
                )
              }
              liguilla={concurso?.cantidadHumoristasLiguilla}
            />
            <PronosticarCategoria
              title="Revistas"
              conjuntos={revistas}
              setConjuntos={value =>
                setRevistas(
                  asignarClasificacionPorPosicion(
                    value,
                    concurso?.cantidadRevistasLiguilla
                  )
                )
              }
              liguilla={concurso?.cantidadRevistasLiguilla}
            />
            <PronosticarCategoria
              title="Comparsas"
              conjuntos={comparsas}
              setConjuntos={value =>
                setComparsas(
                  asignarClasificacionPorPosicion(
                    value,
                    concurso?.cantidadComparsasLiguilla
                  )
                )
              }
              liguilla={concurso?.cantidadComparsasLiguilla}
            />
          </div>
        </div>
        <hr></hr>
        <div className="d-flex row justify-content-center w-auto">
          <div className="col text-left">
            <Button
              variant={"outlined"}
              onClick={() => {
                navigate("/backoffice/Concursos");
              }}
            >
              Cancelar
            </Button>
          </div>
          <div className="col text-right">
            <Button variant={"contained"} onClick={handleGuardar}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(Liguilla, {
  onRedirecting: () => <Loading />
});
