import React, { useEffect } from "react";
import { useTyping } from "./useTyping";
import { useEffectSinEjecucionInicial } from "./useEffectSinEjecucionInicial";

const useTypeThenRun = (textoBusqueda, callbackFn, params = []) => {
  const [dejoDeEscribir] = useTyping();
  useEffectSinEjecucionInicial(() => {
    dejoDeEscribir(textoBusqueda).then(execute => {
      if (execute) {
        callbackFn?.(...params);
      }
    });
  }, [textoBusqueda]);
};

export { useTypeThenRun };
